import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActionStructureGridKind } from '@suzy/shared/data-access/suzy-sdk';
import { DeviceService } from '@suzy/shared/tools/device';
declare var $;

//TODO: Move these interfaces to the shared location
interface FakeCorner {
  top: string;
  left: string;
  width: string;
  height: string;
  isSet: boolean;
}

@Component({
  selector: 'suzy-action-forms-maxDiff',
  templateUrl: './maxDiff.component.html',
  styleUrls: ['./maxDiff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaxDiffComponent implements OnInit {
  timeToAction: number;
  timeToActionIsUpdated = false;

  @Input() action: any;
  @Input() mission: any;
  @Input() isLoading: boolean;
  @Input() isSuccess: boolean;
  @Input() disableSkip: boolean;
  @Input() fakeCorner: FakeCorner;
  @Input() errorMessage: string;
  @Input() disableButton: Boolean = false;
  @Input() isSkipping: boolean;
  @Input() isSubmitting: boolean;
  @Input() isBannedOrDisabled: boolean;

  @Output() mouseEvent = new EventEmitter<MouseEvent>();
  @Output() timeToActionUpdate = new EventEmitter<number>();
  @Output() updateMetaDataValidationCount = new EventEmitter();
  @Output() errorMessageChange = new EventEmitter<string>();
  @Output() skip = new EventEmitter<Event>();
  @Output() goFormSubmit = new EventEmitter<any>();
  @Output() viewOptionImageEvent = new EventEmitter<any>();

  submitted = false;
  lowItem: any;
  highItem: any;
  listArry = [];

  @ViewChild('f') responseForm: NgForm;

  isDarkMode: boolean;
  imageModalIsActive: boolean;
  activeImageUrl: string;
  activeImageAltText: string;
  currentChoiceIndex: number;
  mcOptionsExcludingNone = [];
  isPreview: boolean;

  constructor(private deviceService: DeviceService) {}

  ngOnInit(): void {
    this.listArry = this.action.maxdiff.attributes;
    this.isDarkMode = $('html').hasClass('dark');
    this.listArry.map(list => {
      (list.isHighSelected = false), (list.isLowSelected = false);
    });
    this.isPreview = document.body.classList.contains('isPreview');
  }

  onMouseMoveEvent(event: MouseEvent) {
    this.mouseEvent.emit(event);
  }

  updateTimeToAction(): void {
    if (!this.timeToActionIsUpdated) {
      this.timeToAction = Date.now();
      this.timeToActionUpdate.emit(this.timeToAction);
    }
    this.timeToActionIsUpdated = true;
  }

  selectChoice(item, coloumName, coloumId) {
    this.errorMessage = '';
    if (coloumName === 'low') {
      this.lowItem = {
        answer_id_column: coloumId,
        answer_id_row: item.answer_id
      };
      item.isHighSelected = false;
      item.isLowSelected = true;
    } else {
      this.highItem = {
        answer_id_column: coloumId,
        answer_id_row: item.answer_id
      };
      item.isHighSelected = true;
      item.isLowSelected = false;
    }
    if (
      this.lowItem &&
      this.highItem &&
      this.lowItem.answer_id_row === this.highItem.answer_id_row
    ) {
      if (coloumName === 'low') {
        this.highItem = null;
      } else {
        this.lowItem = null;
      }
    }
    this.action['pairAnswers'] = [this.lowItem, this.highItem];
  }

  onSubmit() {
    this.submitted = true;
    if (this.lowItem && this.highItem) {
      this.updateMetaDataValidationCount.emit();
      this.goFormSubmit.emit();
    } else {
      this.errorMessage = `Please select ONE ${this.action.maxdiff.low_value_ui_text} and ONE ${this.action.maxdiff.high_value_ui_text} option`;
    }
  }

  onSkip(event: Event) {
    this.skip.emit(event);
  }

  viewOptionImage(option: any, index?: number): void {
    this.viewOptionImageEvent.emit({ option, index });
  }
}
