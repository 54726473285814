import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from 'apps/crowdtap/src/app/core/services/settings.service';
@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements AfterViewInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  el_wrapper: HTMLElement;
  reason: string;
  darkMode?: boolean;

  constructor(
    private route: ActivatedRoute,
    private settingService: SettingsService
  ) {}

  ngOnInit(): void {
    this.reason = this.route.snapshot.queryParams['reason'];
    this.settingService.isDarkModeSelected$.subscribe(
      darkMode => (this.darkMode = darkMode)
    );
  }

  ngAfterViewInit(): void {
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
  }

  onBackToTop(): void {
    this.el_wrapper.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }
}
