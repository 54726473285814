<form novalidate="" #f="ngForm" class="h-full">
  <!-- Form Content Starts -->
  <div class="flex flex-col h-full form">
    <div class="text-sm form-group form-filling">
      <div
        class="flex items-start py-2 option-container"
        [ngClass]="{ 'mb-4': !last, 'mb-8': last }"
        *ngFor="let option of turf.options; let i = index; let last = last"
      >
        <ng-container *ngIf="!option.is_none && !option.piped_is_none">
          <label
            for="{{ 'checkbox-' + i }}"
            [attr.aria-labelledby]="'checkbox-' + i + '-label-container'"
            class="flex items-center"
            tabindex="0"
          >
            <input
              class="sr-only"
              type="checkbox"
              [checked]="option.isSelected"
              [value]="option.answer_id"
              (change)="onCheckBoxChange(option, $event, i)"
              id="{{ 'checkbox-' + i }}"
            />
            <div
              *ngIf="!option.isSelected"
              class="w-6 h-6 mr-4 border-2 min-w-6 border-gray"
            ></div>
            <div
              *ngIf="option.isSelected"
              class="flex items-center justify-center w-6 h-6 mr-4 min-w-6 bg-blue-dark"
            >
              <span class="text-xs text-white fa fa-check"></span>
            </div>
            <div
              class="text-base font-semibold md:text-lg"
              id="{{ 'checkbox-' + i + '-label-container' }}"
            >
              {{ option.answer_text }}
              <span
                class="check-icon"
                [attr.data-track]="'member-turf-answer-choice-' + i"
              >
              </span>
            </div>
          </label>
        </ng-container>

        <ng-container *ngIf="option.is_none || option.piped_is_none">
          <label
            for="{{ 'checkbox-' + i }}"
            [attr.aria-labelledby]="'checkbox-' + i + '-label-container'"
            class="flex items-center"
            tabindex="0"
          >
            <input
              class="sr-only"
              type="checkbox"
              [checked]="option.isSelected"
              [value]="option.answer_id"
              (change)="onCheckBoxChange(option, $event, i)"
              id="{{ 'checkbox-' + i }}"
            />
            <div
              *ngIf="!option.isSelected"
              class="w-6 h-6 mr-4 border-2 min-w-6 border-gray"
            ></div>
            <div
              *ngIf="option.isSelected"
              class="flex items-center justify-center w-6 h-6 mr-4 min-w-6 bg-blue-dark"
            >
              <span class="text-xs text-white fa fa-check"></span>
            </div>
            <div
              class="text-base font-semibold md:text-lg"
              id="{{ 'checkbox-' + i + '-label-container' }}"
            >
              {{ turf.noneText || 'None of the above' }}
              <span
                class="check-icon"
                data-track="member-turf-none-of-the-above"
              >
              </span>
            </div>
          </label>
        </ng-container>
      </div>

      <p
        class="flex items-center my-6 error-inline-msg text-error"
        *ngIf="errorMessage || isBannedOrDisabled"
      >
        <svg
          class="inline-block mr-2"
          width="24"
          height="24"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="flex-shrink: 0"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.50004 4.04351C8.06282 4.04351 7.70837 4.39795 7.70837 4.83518V8.00185C7.70837 8.43907 8.06282 8.79351 8.50004 8.79351C8.93727 8.79351 9.29171 8.43907 9.29171 8.00185V4.83518C9.29171 4.39795 8.93727 4.04351 8.50004 4.04351ZM9.22837 10.8677C9.21104 10.8173 9.18708 10.7694 9.15712 10.7252L9.06212 10.6065C8.83447 10.3818 8.49402 10.3163 8.1992 10.4402C8.10326 10.4803 8.0149 10.5366 7.93795 10.6065C7.78976 10.7559 7.70715 10.9581 7.70837 11.1686C7.70962 11.272 7.73114 11.3742 7.7717 11.4694C7.84368 11.6683 8.00031 11.8249 8.1992 11.8969C8.39082 11.9816 8.60925 11.9816 8.80087 11.8969C8.99976 11.8249 9.15639 11.6683 9.22837 11.4694C9.26894 11.3742 9.29045 11.272 9.2917 11.1686C9.29559 11.1159 9.29559 11.0629 9.2917 11.0102C9.27807 10.9597 9.25671 10.9117 9.22837 10.8677ZM8.50004 0.0852051C4.12779 0.0852051 0.583374 3.62962 0.583374 8.00187C0.583374 12.3741 4.12779 15.9185 8.50004 15.9185C12.8723 15.9185 16.4167 12.3741 16.4167 8.00187C16.4167 5.90224 15.5826 3.88861 14.098 2.40394C12.6133 0.91928 10.5997 0.0852051 8.50004 0.0852051ZM8.50005 14.3352C5.00225 14.3352 2.16672 11.4996 2.16672 8.00185C2.16672 4.50404 5.00225 1.66851 8.50005 1.66851C11.9979 1.66851 14.8334 4.50404 14.8334 8.00185C14.8334 9.68155 14.1661 11.2925 12.9784 12.4802C11.7907 13.6679 10.1798 14.3352 8.50005 14.3352Z"
            fill="#FF5F57"
          />
        </svg>

        <ng-container *ngIf="isBannedOrDisabled; else errorDisplay">
          <span style="white-space: normal">
            {{ 'unauthorized.yourAccountFlagged' | translate }}
            <a
              href="https://support.crowdtap.com/hc/en-us/articles/16762298155284"
              target="_blank"
              >{{ 'unauthorized.clickHereMission' | translate }}</a
            >.
          </span>
        </ng-container>
        <ng-template #errorDisplay>
          {{ errorMessage }}
        </ng-template>
      </p>
    </div>
    <div
      class="relative flex items-center justify-center pb-6 space-x-2 text-sm font-bold align-middle md:text-base md:space-x-16 form-group form-cta"
    >
      <ng-container *ngIf="!action.prevent_skip">
        <button
          type="button"
          class="w-36 text-blue-dark skip"
          (click)="onSkip($event)"
          data-track="member-turf-skip"
          (mousemove)="onMouseMoveEvent($event)"
          [disabled]="isTrapQuestion || disableSkip || isSkipping"
          *ngIf="disableButton"
          [ngClass]="{ loading: isSkipping }"
        >
          <span class="btn-label"> {{ 'general.skip' | translate }}</span>
          <span class="dot dot-1"></span>
          <span class="dot dot-2"></span>
          <span class="dot dot-3"></span>
        </button>
      </ng-container>

      <button
        class="animation-submit-btn"
        (mousemove)="onMouseMoveEvent($event)"
        (click)="onSubmit()"
        data-track="member-turf-continue"
        [disabled]="isSkipping || isSubmitting || isLoading"
        [ngClass]="{
          primarySection: !isLoading && !isSuccess,
          loadingSection: isLoading,
          successSection: isSuccess
        }"
        *ngIf="!action.is_prerequisite; else showPrerequisiteBtn"
      >
        <div class="primaryTitle text submit-label">
          {{ 'general.submit' | translate }}
        </div>
        <div class="primaryTitle text continue-label">
          {{ 'general.continue' | translate }}
        </div>

        <div class="loadingTitle text">
          <suzy-spinner
            class="absolute left-0 right-0 button-spinner top-3"
            size="small"
            button="true"
            [round]="7"
            [color]="'#ffffff'"
            [overlay]="false"
          >
          </suzy-spinner>
        </div>

        <div class="successTitle text">
          <svg class="animated-check" viewBox="0 0 24 24">
            <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
          </svg>
        </div>
      </button>

      <ng-template #showPrerequisiteBtn>
        <button
          class="relative px-8 overflow-hidden text-sm font-bold text-white transition ease-in-out rounded-full md:text-base w-52 bg-blue-dark h-14"
          [ngClass]="{ 'animated-btn ': isSuccess, 'load-start': isLoading }"
          [disabled]="isSkipping || isSubmitting"
          (click)="onSubmitPrerequisite()"
        >
          <suzy-spinner
            *ngIf="isLoading"
            class="absolute left-8 button-spinner top-7"
            size="small"
            button="true"
            [round]="7"
            [color]="'#ffffff'"
            [overlay]="false"
          >
          </suzy-spinner>
          <svg
            *ngIf="isSuccess"
            class="absolute inline-block left-1 button-spinner top-0.5"
            xmlns="http://www.w3.org/2000/svg"
            width="51"
            height="51"
            viewBox="0 0 90 91"
            fill="none"
          >
            <path
              d="M45 90.998C69.8528 90.998 90 70.8509 90 45.998C90 21.1452 69.8528 0.998047 45 0.998047C20.1472 0.998047 0 21.1452 0 45.998C0 70.8509 20.1472 90.998 45 90.998Z"
              fill="#68C97F"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M67.31 28.8067C66.6774 28.1688 65.8163 27.8101 64.9179 27.8101C64.0196 27.8101 63.1585 28.1688 62.5258 28.8067L37.4257 53.9404L26.8803 43.3613C25.9956 42.5067 24.7222 42.189 23.5397 42.5279C22.3573 42.8668 21.4454 43.8107 21.1476 45.0042C20.8499 46.1977 21.2115 47.4594 22.0962 48.314L35.0337 61.2515C35.6663 61.8893 36.5274 62.2481 37.4257 62.2481C38.3241 62.2481 39.1852 61.8893 39.8178 61.2515L67.31 33.7593C68.0014 33.1215 68.3947 32.2236 68.3947 31.283C68.3947 30.3423 68.0014 29.4445 67.31 28.8067Z"
              fill="white"
            />
          </svg>
          {{ 'general.submit' | translate }}
        </button>
      </ng-template>
    </div>
  </div>
</form>
