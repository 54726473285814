import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { MissionService } from '@suzy/shared/tools/mission';
import { PreRollKind } from '@asksuzy/typescript-sdk';
import { Subject } from 'rxjs';
import swal from 'sweetalert2';
import { AppAlertService } from '../../../core/services/app-alert/app-alert.service';
import { SkipModalComponent } from '../skip-modal/skip-modal.component';
import { SkipModalService } from '../../../core/services/skip-modal-service';
import { beforeModalDismiss } from '../actions.service';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';

export interface IPhotoResponse {
  response_value: string;
  response_display: string;
  id: string;
  mime_type: string;
  url: string;
}

@Component({
  selector: 'app-photo-share',
  templateUrl: './photo-share.component.html',
  styleUrls: ['./photo-share.component.scss']
})
export class PhotoShareComponent implements OnInit, OnDestroy {
  @ViewChild('f') responseForm: NgForm;
  action: any = {};
  mission: any = {};
  subject: Subject<any> = new Subject<any>();
  uploadedPhotoUrl: string;
  photoCaption = '';
  uploadedPhotos: Array<IPhotoResponse> = [];
  hasPreroll = false;
  prerollViewed = false;
  isSubmitting = false;
  shared = false;

  preRollKind = PreRollKind.none;
  preRollCaption = '';
  preRollUrl = '';
  disableSkip: boolean;
  showSurveyProgress = false;

  constructor(
    private sdk: SuzySdkService,
    private alerts: AppAlertService,
    private missionService: MissionService,
    private skipModalService: SkipModalService,
    private modals: NgbModal,
    private hostElement: ElementRef,
    private launchDarklyService: LaunchDarklyService
  ) {}

  ngOnInit(): void {
    this.preRollKind = this.action.preroll_kind;
    this.preRollCaption = this.action.preroll_instruction;
    this.showSurveyProgress =
      this.launchDarklyService.getCWDT21752418SurveyProgress();
    if (
      this.action.preroll_kind !== 'undefined' &&
      this.action.preroll_kind !== PreRollKind.none
    ) {
      if (
        this.action.preroll_kind === PreRollKind.image &&
        this.action.preroll
      ) {
        this.hasPreroll = true;
        this.preRollUrl = this.action.preroll.thumbs.full.url;
      } else if (
        this.action.preroll_kind === PreRollKind.link &&
        this.action.preroll_body
      ) {
        this.hasPreroll = true;
        this.preRollUrl = this.action.preroll_body;
      }
    }

    if (this.mission.first_action && this.mission.first_action.prevent_skip) {
      this.disableSkip = this.mission.first_action.prevent_skip;
    }
    this.skipModalService.isSkip.subscribe(data => {
      if (data === 'photo_share') {
        this.skipAction();
      }
    });
  }

  init(): void {
    // Do NOT remove
    // ActionLoaderComponent requires this
  }

  ngOnDestroy(): void {
    this.subject.complete();
    this.subject = undefined;
  }

  onPrerollViewedEvent(): void {
    this.prerollViewed = true;
  }

  skipAction(): void {
    this.isSubmitting = true;
    this.hostElement.nativeElement
      .closest('.modal')
      .classList.add('is-skipping');
    this.sdk.ProtocolRespond.skip(
      this.mission.brand_id,
      this.mission.mission_id,
      this.action.action_id
    ).subscribe(
      data => {
        if (data.success) {
          this.skipModalService.isSuccess.next(true);
          setTimeout(() => {
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data.meta
            );
            this.subject.next(data);
          }, 1000);
        } else {
          this.skipModalService.isSuccess.next(false);
          document.getElementById('skipBtn').classList.remove('loading');
          let message = data.message;
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
          }
          this.alerts.notify({ title: 'Error', message });
        }
      },
      error => {
        this.skipModalService.isSuccess.next(false);
        document.getElementById('skipBtn').classList.remove('loading');
        this.alerts.notify({ title: 'Error', message: error });
      },
      () => {
        this.isSubmitting = false;
      }
    );
  }

  openSkipModal(): void {
    const modalClass = `modal-${new Date().getTime()}`;
    let modal;
    modal = this.modals.open(SkipModalComponent, {
      windowClass: modalClass,
      beforeDismiss: () => {
        return beforeModalDismiss(modalClass, 'skip');
      }
    });
    const instance = modal.componentInstance as SkipModalComponent;
    instance.kind = this.mission.mission_kind;
    instance.mission = this.mission;
    instance.action = this.mission.first_action;
    instance.actionService = null;
    instance.actionType = 'link_share';
  }

  skip($event): void {
    $event.preventDefault();
    this.openSkipModal();
  }

  onSubmit(): void {
    const userResponse = {
      action_id: this.action.action_id,
      answer_id: this.action.share_photo.answer_id,
      photos: this.uploadedPhotos,
      response_value: this.photoCaption,
      response_display: this.photoCaption,
      answer_items: this.action.share_photo.platforms
    };

    this.isSubmitting = true;

    this.sdk.ProtocolRespond.respond(
      this.mission.brand_id,
      this.mission.mission_id,
      userResponse
    ).subscribe(
      data => {
        this.isSubmitting = false;
        if (data.success) {
          this.missionService.onMissionAnswered(
            this.mission.mission_id,
            data.meta
          );
          this.subject.next(data);
        } else {
          let message = data.message;
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
          }
          this.alerts.notify({ title: 'Error', message });
        }
      },
      error => {
        this.alerts.notify({ title: 'Error', message: error });
        this.isSubmitting = false;
      },
      () => {
        this.isSubmitting = false;
      }
    );
  }

  getUploadUrl = (name): void => {
    return this.sdk.Asset.getUploadInfoAsync(
      'image',
      'action_response',
      name,
      this.action.action_id,
      undefined,
      'PUT',
      'auto'
    );
  };

  onUploadComplete(uploadedFile): void {
    this.uploadedPhotos.push({
      response_value: this.photoCaption,
      response_display: this.photoCaption,
      id: uploadedFile.id,
      mime_type: uploadedFile.mime_type,
      url: uploadedFile.url
    });
    this.uploadedPhotoUrl = uploadedFile.url;
  }

  onShared(): void {
    this.shared = true;
  }

  showGuidelines(event): void {
    swal.fire({
      customClass: 'general-modal',
      title: 'Photo Approval Guidelines',
      html: `
        <div class="text-left">
          <p>
            When you share your photos on Crowdtap&reg;, you’re helping build more
            honest and authentic feedback with brands that value your opinion.
          </p>
          <b>You ensure the photos you upload are your own.</b>
          <p>
            You make Crowdtap&reg; what it is, so don’t submit photos that aren’t
            your own. If you do, your photo may be flagged and removed, and
            you will forfeit any points for the completion of that action.
          </p>
          <b>Upload photos that are 20 MB or smaller.</b>
          <p>
            Because of varying internet speeds and loading times, photos
            that are large may have trouble uploading. If you’re having
            trouble uploading, try uploading a smaller photo size. Here’s
            a great.
          </p>
          <b>Upload JPGs, PNGs, or GIFs</b>
          <p>
            Please upload an image file that has a .JPG, .PNG, or .GIF at
            the end of the file name. If you’re trying to upload a photo
            that has a file ending like .TIFF or .PDF, it won’t upload.
          </p>
        </div>`
    });

    event.stopPropagation();
  }

  calculateCompletion(stepPriority: number, actionCount: number): number {
    return Math.round(((stepPriority - 1) / actionCount) * 100);
  }
}
