import { Injectable } from '@angular/core';
import {
  IAvailableChallenge,
  IChallengeButton,
  IChallengeInfo,
  IPreviewSegmentLinkCopied,
  IPreviewSegmentMission,
  IReward,
  IRewardGoalMilestone,
  IRewardGoalReached,
  IRewardGoalTapped,
  IRewardGoalTrackId,
  IRewardOrder,
  ISegmentAction,
  ISegmentActionStarted,
  ISegmentDashboard,
  ISegmentDashboardCleared,
  ISegmentDashboardTapped,
  ISegmentError,
  ISegmentErrorAction,
  ISegmentErrorAuth,
  ISegmentErrorPromo,
  ISegmentIdentify,
  ISegmentMedia,
  ISegmentMission,
  ISegmentMissionComplete,
  ISegmentOnboardingTooltipViewed,
  ISegmentReward,
  ISegmentThemeUpdated,
  ISegmentTrapFailedModal,
  ISetRewardGoalTapped,
  ISMSVerificationError,
  ISMSVerificationRedemption,
  segment_special_tile,
  segment_theme,
  SegmentChallengeStage,
  SegmentErrorSource,
  SegmentPermissionName,
  SegmentRewardGoalLocation,
  SegmentSMSVerificationErrorType
} from './segment-keys.model';
import {
  ActionKind,
  ActionStructureGridKind,
  ActionStructureMultipleChoiceKind,
  ChallengePeriod,
  ChallengeType,
  MissionKind,
  PreRollKind,
  RewardCategory
} from '@suzy/shared/data-access/suzy-sdk';
import { TrustArcService } from './trustarc.service';
import { Observable, Subject } from 'rxjs';

declare global {
  interface Window {
    analytics: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SegmentService {
  token: string;
  eventPrefix: string;

  constructor(private trustArcService: TrustArcService) {}

  initialize(token: string, eventPrefix?: string): SegmentService {
    this.token = token;
    this.eventPrefix = eventPrefix || '';

    return this;
  }

  init(): void {
    // init
  }

  windowObjectNotLoaded(): boolean {
    return window === undefined || window.analytics === undefined;
  }

  load(): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }

    this.trustArcService.getDecision();
    window.analytics.load(this.token, {
      integrations: {
        'Facebook Pixel': this.trustArcService.advertisingAllowed,
        'Twitter Ads': this.trustArcService.advertisingAllowed,
        'Google Analytics': this.trustArcService.functionalAllowed,
        'Google Analytics 4 Web': this.trustArcService.functionalAllowed,
        Mixpanel: this.trustArcService.functionalAllowed,
        'Mixpanel (Legacy)': this.trustArcService.functionalAllowed,
        'Mixpanel (Actions)': this.trustArcService.functionalAllowed,
        Hotjar: this.trustArcService.functionalAllowed,
        Appcues: true,
        Appboy: true,
        'Google Tag Manager': true,
        'Segment.io': true
      }
    });
  }

  reset(): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.reset();
  }

  track(name: string, properties?: any): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track(name, properties || {});
  }

  trackMission(name: string, properties: ISegmentMission): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }

    const trackName = this.eventPrefix + name;
    window.analytics.track(trackName, {
      action_count: properties.action_count,
      brand_id: properties.brand_id,
      mission_id: properties.mission_id,
      points_available: properties.points_available,
      mission_kind:
        properties.mission_kind === undefined
          ? undefined
          : MissionKind[properties.mission_kind],
      has_cover: properties.has_cover,
      monadic_variant_quantity: properties.monadic_variant_quantity,
      tag:
        properties.tag === undefined
          ? undefined
          : segment_special_tile[properties.tag],
      weight: properties.weight
    });
  }

  trackMissionComplete(properties: ISegmentMissionComplete): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }

    window.analytics.track(`${this.eventPrefix}Mission Completed`, {
      action_count: properties.action_count,
      brand_id: properties.brand_id,
      mission_id: properties.mission_id,
      points_earned: properties.points_earned,
      mission_kind:
        properties.mission_kind === undefined
          ? undefined
          : MissionKind[properties.mission_kind],
      tag:
        properties.tag === undefined
          ? undefined
          : segment_special_tile[properties.tag],
      weight: properties.weight
    });
  }

  trackAction(name: string, properties: ISegmentAction): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }

    const trackName = this.eventPrefix + name;
    window.analytics.track(trackName, {
      brand_id: properties.brand_id,
      action_id: properties.action_id,
      mission_id: properties.mission_id,
      action_kind:
        properties.action_kind === undefined
          ? undefined
          : ActionKind[properties.action_kind],
      action_name: properties.action_name,
      grid_kind:
        properties.grid_kind === undefined
          ? undefined
          : ActionStructureGridKind[properties.grid_kind],
      multiple_choice_kind:
        properties.multiple_choice_kind === undefined
          ? undefined
          : properties.multiple_choice_kind ===
            ActionStructureMultipleChoiceKind.monadic
          ? 'rating' // work around until enum 1 = rating
          : ActionStructureMultipleChoiceKind[properties.multiple_choice_kind],
      monadic_variant_id: properties.monadic_variant_id,
      tag:
        properties.tag === undefined
          ? undefined
          : segment_special_tile[properties.tag]
    });
  }

  trackActionStarted(properties: ISegmentActionStarted): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track(`${this.eventPrefix}Action Started`, {
      brand_id: properties.brand_id,
      action_id: properties.action_id,
      mission_id: properties.mission_id,
      action_kind:
        properties.action_kind === undefined
          ? undefined
          : ActionKind[properties.action_kind],
      action_name: properties.action_name,
      grid_kind:
        properties.grid_kind === undefined
          ? undefined
          : ActionStructureGridKind[properties.grid_kind],
      has_monadic: properties.has_monadic,
      image_answers: properties.image_answers,
      multiple_choice_kind:
        properties.multiple_choice_kind === undefined
          ? undefined
          : properties.multiple_choice_kind ===
            ActionStructureMultipleChoiceKind.monadic
          ? 'rating' // work around until enum 1 = rating
          : ActionStructureMultipleChoiceKind[properties.multiple_choice_kind],
      monadic_variant_id: properties.monadic_variant_id,
      passive_preroll_enabled: properties.passive_preroll_enabled,
      points_available: properties.points_available,
      preroll_kind:
        properties.preroll_kind === undefined
          ? undefined
          : PreRollKind[properties.preroll_kind],
      tag:
        properties.tag === undefined
          ? undefined
          : segment_special_tile[properties.tag]
    });
  }

  trackMediaOpen(properties: ISegmentMedia): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track(`${this.eventPrefix}Media Opened`, {
      action_id: properties.action_id,
      action_kind:
        properties.action_kind === undefined
          ? undefined
          : ActionKind[properties.action_kind],
      asset_id: properties.asset_id,
      asset_url: properties.asset_url,
      brand_id: properties.brand_id,
      mission_id: properties.mission_id,
      preroll_kind:
        properties.preroll_kind === undefined
          ? undefined
          : PreRollKind[properties.preroll_kind],
      is_on_question: properties.is_on_question,
      image_attribution: properties.image_attribuion
    });
  }

  // only referenced in app-your-cart which may no longer be implemented
  trackReward(name: string, properties: ISegmentReward): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track(name, {
      points_cost: properties.points_cost,
      retailer: properties.retailer,
      reward_id: properties.reward_id,
      reward_category:
        properties.reward_category === undefined
          ? undefined
          : RewardCategory[properties.reward_category],
      reward_value: properties.reward_value
    });
  }

  trackDashboardView(properties: ISegmentDashboard): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Dashboard Viewed', {
      actions_available: properties.actions_available,
      api_missions_total_available: properties.api_missions_total_available,
      special_birthday: properties.special_birthday,
      special_profile: properties.special_profile,
      special_sparkle: properties.special_sparkle,
      special_dollar_circle: properties.special_dollar_circle,
      special_celebrate: properties.special_celebrate,
      special_question: properties.special_question,
      special_star_target: properties.special_star_target,
      special_streak: properties.special_streak,
      special_clock_nine: properties.special_clock_nine,
      special_user_exclamation: properties.special_user_exclamation,
      special_chat: properties.special_chat,
      special_double_points: properties.special_double_points,
      weighted_missions: properties.weighted_missions,
      special_pumpkin_sweater: properties.special_pumpkin_sweater,
      special_leaf_sweater: properties.special_leaf_sweater,
      special_fall_sweater: properties.special_fall_sweater,
      special_cauldron: properties.special_cauldron,
      special_champagne: properties.special_champagne,
      special_new_year: properties.special_new_year,
      special_shopping: properties.special_shopping,
      special_christmas_tree: properties.special_christmas_tree,
      special_sweater_christmas_tree: properties.special_sweater_christmas_tree,
      special_sweater_snowflake: properties.special_sweater_snowflake,
      special_sweater_snowman: properties.special_sweater_snowman,
      special_pumpkin: properties.special_pumpkin
    });
  }

  trackDashboardCleared(properties: ISegmentDashboardCleared): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Dashboard Cleared', {
      points_earned: properties.points_earned,
      ui_message: properties.ui_message
    });
  }

  trackEmptyDashboardButtonTapped(properties: ISegmentDashboardTapped): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Empty Dashboard Button Tapped', {
      button_text: properties.button_text,
      empty_dashboard_message: properties.empty_dashboard_message
    });
  }

  trackDashboardFilter(dashboard_filter: string): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Dashboard Filtered', {
      dashboard_filter
    });
  }

  trackReferralSendClicked(email_referees: Array<string>): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Referral Send Clicked', {
      email_referees
    });
  }

  trackReferralLinkCopied(incentive_program_id: string): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Referral Link Copied', {
      incentive_program_id
    });
  }

  trackThemeUpdated(properties: ISegmentThemeUpdated): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Theme Updated', {
      theme: segment_theme[properties.theme]
    });
  }

  trackQuestionFailedModalViewed(properties: ISegmentTrapFailedModal): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track(
      'Trap Question Failed Modal Viewed',
      properties || {}
    );
  }

  trackError(name: string, properties: ISegmentError): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track(name, properties);
  }

  trackPromoError(properties: ISegmentErrorPromo): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Promo Code Error', properties);
  }

  trackAuthError(name: string, properties: ISegmentErrorAuth): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track(name, properties);
  }

  trackActionError(properties: ISegmentErrorAction): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Action Errored', {
      action_id: properties.action_id,
      action_kind: properties.action_kind
        ? ActionKind[properties.action_kind]
        : undefined,
      brand_id: properties.brand_id,
      error_code: properties.error_code,
      error_source: properties.error_source,
      error_message: properties.error_message,
      error_ui_message: properties.error_ui_message,
      mission_id: properties.mission_id,
      mission_kind: properties.action_kind
        ? MissionKind[properties.action_kind]
        : undefined,
      monadic_variant_id: properties.monadic_variant_id,
      tag: properties.tag ? segment_special_tile[properties.tag] : undefined
    });
  }

  trackValidationError(properties: ISegmentErrorAction): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Validation Error Viewed', {
      action_id: properties.action_id,
      action_kind: properties.action_kind
        ? ActionKind[properties.action_kind]
        : undefined,
      brand_id: properties.brand_id,
      error_code: properties.error_code,
      error_source: properties.error_source,
      error_message: properties.error_message,
      error_ui_message: properties.error_ui_message,
      mission_id: properties.mission_id,
      mission_kind: properties.action_kind
        ? MissionKind[properties.action_kind]
        : undefined,
      monadic_variant_id: properties.monadic_variant_id,
      tag: properties.tag ? segment_special_tile[properties.tag] : undefined
    });
  }

  trackPreviewMission(name: string, properties: IPreviewSegmentMission): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track(name, properties);
  }

  trackPreviewLinkCopied(properties: IPreviewSegmentLinkCopied): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Preview Link Copied', properties);
  }

  trackPermissionToggle(isEnabled: boolean, name: SegmentPermissionName): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Permission Toggled', {
      is_enabled: isEnabled,
      permission_name: SegmentPermissionName[name]
    });
  }

  trackSetRewardGoalTapped(properties: ISetRewardGoalTapped): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Set Reward Goal Tapped', {
      total_points: properties.total_points,
      reward_goal_location:
        SegmentRewardGoalLocation[properties.reward_goal_location]
    });
  }

  trackRewardGoalNamesViewed(total_points: number): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Reward Goal Names Viewed', {
      total_points
    });
  }

  trackRewardGoalAmountsViewed(properties: IReward): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Reward Goal Amounts Viewed', {
      reward_category: RewardCategory[properties.reward_category],
      reward_id: properties.reward_id,
      reward_name: properties.reward_name,
      reward_photo: properties.reward_photo,
      total_points: properties.total_points
    });
  }

  trackRewardGoalConfirmationViewed(properties: IRewardGoalTrackId): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Reward Goal Confirmation Viewed', {
      reward_category: RewardCategory[properties.reward_category],
      reward_goal_points_cost: properties.reward_goal_points_cost,
      reward_id: properties.reward_id,
      reward_name: properties.reward_name,
      reward_photo: properties.reward_photo,
      reward_value: properties.reward_value,
      total_points: properties.total_points,
      track_reward_id: properties.track_reward_id,
      user_track_reward_goal_id: properties.user_track_reward_goal_id
    });
  }

  trackPointsPillTapped(properties: IRewardGoalTrackId): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Points Pill Tapped', {
      reward_category: RewardCategory[properties.reward_category],
      reward_goal_points_cost: properties.reward_goal_points_cost,
      reward_id: properties.reward_id,
      reward_name: properties.reward_name,
      reward_photo: properties.reward_photo,
      reward_value: properties.reward_value,
      total_points: properties.total_points,
      track_reward_id: properties.track_reward_id,
      user_track_reward_goal_id: properties.user_track_reward_goal_id
    });
  }

  trackRewardGoalReachedModalViewed(properties: IRewardGoalReached): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Reward Goal Reached Modal Viewed', {
      points_cost: properties.points_cost,
      reward_category: RewardCategory[properties.reward_category],
      reward_goal_points_cost: properties.reward_goal_points_cost,
      reward_id: properties.reward_id,
      reward_name: properties.reward_name,
      reward_photo: properties.reward_photo,
      reward_value: properties.reward_value,
      total_points: properties.total_points,
      user_track_reward_goal_id: properties.user_track_reward_goal_id
    });
  }

  trackRewardOrderViewed(properties: IRewardOrder): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Reward Order Viewed', {
      points_cost: properties.points_cost,
      retailer: properties.retailer,
      reward_category: RewardCategory[properties.reward_category],
      reward_goal: properties.reward_goal ? 'yes' : 'no',
      reward_goal_points_cost: properties.reward_goal_points_cost,
      reward_id: properties.reward_id,
      reward_name: properties.reward_name,
      reward_photo: properties.reward_photo,
      reward_value: properties.reward_value,
      total_points: properties.total_points,
      user_track_reward_goal_id: properties.user_track_reward_goal_id
    });
  }

  trackRewardOrderSubmitted(properties: IRewardOrder): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Reward Order Submitted', {
      points_cost: properties.points_cost,
      retailer: properties.retailer,
      reward_category: RewardCategory[properties.reward_category],
      reward_goal: properties.reward_goal ? 'yes' : 'no',
      reward_goal_points_cost: properties.reward_goal_points_cost,
      reward_id: properties.reward_id,
      reward_name: properties.reward_name,
      reward_photo: properties.reward_photo,
      reward_value: properties.reward_value,
      total_points: properties.total_points,
      user_track_reward_goal_id: properties.user_track_reward_goal_id
    });
  }

  trackRewardGoalDeleteModalViewed(properties: IRewardGoalTrackId): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Reward Goal Delete Modal Viewed', {
      reward_category: RewardCategory[properties.reward_category],
      reward_goal_points_cost: properties.reward_goal_points_cost,
      reward_id: properties.reward_id,
      reward_name: properties.reward_name,
      reward_photo: properties.reward_photo,
      reward_value: properties.reward_value,
      total_points: properties.total_points,
      track_reward_id: properties.track_reward_id,
      user_track_reward_goal_id: properties.user_track_reward_goal_id
    });
  }

  trackRewardGoalDeleteModalTapped(properties: IRewardGoalTrackId): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Reward Goal Delete Modal Tapped', {
      reward_category: RewardCategory[properties.reward_category],
      reward_goal_points_cost: properties.reward_goal_points_cost,
      reward_id: properties.reward_id,
      reward_name: properties.reward_name,
      reward_photo: properties.reward_photo,
      reward_value: properties.reward_value,
      total_points: properties.total_points,
      track_reward_id: properties.track_reward_id,
      user_track_reward_goal_id: properties.user_track_reward_goal_id
    });
  }

  trackRewardGoalUpdateTapped(properties: IRewardGoalTapped): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Reward Goal Update Tapped', {
      reward_category: RewardCategory[properties.reward_category],
      reward_goal_location:
        SegmentRewardGoalLocation[properties.reward_goal_location],
      reward_goal_points_cost: properties.reward_goal_points_cost,
      reward_name: properties.reward_name,
      reward_photo: properties.reward_photo,
      reward_value: properties.reward_value,
      total_points: properties.total_points,
      track_reward_id: properties.track_reward_id,
      user_track_reward_goal_id: properties.user_track_reward_goal_id
    });
  }

  trackRewardGoalEditTapped(properties: IRewardGoalTapped): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Reward Goal Edit Tapped', {
      reward_category: RewardCategory[properties.reward_category],
      reward_goal_location:
        SegmentRewardGoalLocation[properties.reward_goal_location],
      reward_goal_points_cost: properties.reward_goal_points_cost,
      reward_name: properties.reward_name,
      reward_photo: properties.reward_photo,
      reward_value: properties.reward_value,
      total_points: properties.total_points,
      track_reward_id: properties.track_reward_id,
      user_track_reward_goal_id: properties.user_track_reward_goal_id
    });
  }

  trackRewardGoalMilestoneModalViewed(properties: IRewardGoalMilestone): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Reward Goal Milestone Modal Viewed', {
      reward_category: RewardCategory[properties.reward_category],
      reward_name: properties.reward_name,
      reward_photo: properties.reward_photo,
      reward_value: properties.reward_value,
      total_points: properties.total_points,
      track_reward_id: properties.track_reward_id,
      user_track_reward_goal_id: properties.user_track_reward_goal_id
    });
  }

  trackOnboardingTooltipViewed(
    properties: ISegmentOnboardingTooltipViewed
  ): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Onboarding Tooltip Viewed', {
      ui_message: properties.ui_message,
      type: properties.type,
      dismissed: properties.dismissised
    });
  }

  smsVerificationUponRedemptionViewed(
    properties: ISMSVerificationRedemption
  ): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Sms Verification Upon Redemption Viewed', {
      reward_category: RewardCategory[properties.reward_category],
      points_cost: properties.points_cost,
      reward_name: properties.reward_name,
      reward_photo: properties.reward_photo,
      reward_value: properties.reward_value,
      track_reward_id: properties.track_reward_id,
      user_track_reward_id: properties.user_track_reward_id,
      track_phone_number: properties.track_phone_number
    });
  }

  smsVerificationUponRedemptionCodeSubmissionTapped(
    properties: ISMSVerificationRedemption
  ): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track(
      'Sms Verification Upon Redemption Code Submission Tapped',
      {
        reward_category: RewardCategory[properties.reward_category],
        points_cost: properties.points_cost,
        reward_name: properties.reward_name,
        reward_photo: properties.reward_photo,
        reward_value: properties.reward_value,
        track_reward_id: properties.track_reward_id,
        user_track_reward_id: properties.user_track_reward_id,
        track_phone_number: properties.track_phone_number
      }
    );
  }

  smsVerificationUponRedemptionResendCodeTapped(
    properties: ISMSVerificationRedemption
  ): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track(
      'Sms Verification Upon Redemption Resend Code Tapped',
      {
        reward_category: RewardCategory[properties.reward_category],
        points_cost: properties.points_cost,
        reward_name: properties.reward_name,
        reward_photo: properties.reward_photo,
        reward_value: properties.reward_value,
        track_reward_id: properties.track_reward_id,
        user_track_reward_id: properties.user_track_reward_id,
        track_phone_number: properties.track_phone_number
      }
    );
  }

  smsVerificationUponRedemptionCodeErrorViewed(
    properties: ISMSVerificationError
  ): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track(
      'Sms Verification Upon Redemption Code Error Viewed',
      {
        sms_verification_error_type:
          SegmentSMSVerificationErrorType[
            properties.sms_verification_error_type
          ],
        error_message: properties.error_message,
        error_ui_message: properties.error_ui_message,
        error_source: SegmentErrorSource[properties.error_source],
        reward_category: RewardCategory[properties.reward_category],
        points_cost: properties.points_cost,
        reward_name: properties.reward_name,
        reward_photo: properties.reward_photo,
        reward_value: properties.reward_value,
        track_reward_id: properties.track_reward_id,
        user_track_reward_id: properties.user_track_reward_id,
        track_phone_number: properties.track_phone_number
      }
    );
  }

  challengeIntroModalViewed(properties: IAvailableChallenge): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Challenge Intro Modal Viewed', {
      track_challenge_id: properties.track_challenge_id,
      user_track_challenge_id: undefined,
      user_track_challenge_key: undefined,
      track_id: properties.track_id,
      challenge_name: properties.challenge_name,
      challenge_description: properties.challenge_description,
      challenge_period: ChallengePeriod[properties.challenge_period],
      challenge_points: properties.challenge_points,
      challenge_type: ChallengeType[properties.challenge_type],
      start_utc: properties.start_utc,
      expires_utc: properties.expires_utc
    });
  }

  challengeButtonTapped(properties: IChallengeButton): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Challenge Button Tapped', {
      track_challenge_id: properties.track_challenge_id,
      user_track_challenge_id: undefined,
      user_track_challenge_key: undefined,
      track_id: undefined,
      challenge_name: undefined,
      challenge_description: undefined,
      challenge_period: ChallengePeriod[properties.challenge_period],
      challenge_status: SegmentChallengeStage[SegmentChallengeStage.started],
      challenge_points: properties.points,
      challenge_type: ChallengeType[properties.challenge_type],
      start_utc: undefined,
      expires_utc: undefined
    });
  }

  challengePillTapped(properties: IChallengeInfo): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Challenge Pill Tapped', {
      track_challenge_id: properties.track_challenge_id,
      user_track_challenge_id: properties.user_track_challenge_id,
      user_track_challenge_key: undefined,
      track_id: undefined,
      challenge_name: undefined,
      challenge_description: undefined,
      challenge_period: ChallengePeriod[properties.challenge_period],
      challenge_status: SegmentChallengeStage[properties.challengeStage],
      challenge_points: properties.points,
      challenge_type: ChallengeType[properties.challenge_type],
      start_utc: undefined,
      completed_utc: undefined,
      awarded_utc: undefined,
      award_points: properties.points
    });
  }

  challengeMilestoneViewed(properties: IChallengeInfo): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Challenge In-Progress Milestone Modal Viewed', {
      track_challenge_id: properties.track_challenge_id,
      user_track_challenge_id: properties.user_track_challenge_id,
      user_track_challenge_key: undefined,
      track_id: undefined,
      challenge_name: undefined,
      challenge_description: undefined,
      challenge_period: ChallengePeriod[properties.challenge_period],
      challenge_status: SegmentChallengeStage[properties.challengeStage],
      challenge_points: properties.points,
      challenge_type: ChallengeType[properties.challenge_type],
      start_utc: undefined,
      challenge_milestone_numerator: properties.progress,
      challenge_milestone_denominator: properties.required
    });
  }

  challengeCompletedModalViewed(properties: IChallengeInfo): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Challenge Completed Modal Viewed', {
      track_challenge_id: properties.track_challenge_id,
      user_track_challenge_id: properties.user_track_challenge_id,
      user_track_challenge_key: undefined,
      track_id: undefined,
      challenge_name: undefined,
      challenge_description: undefined,
      challenge_period: ChallengePeriod[properties.challenge_period],
      challenge_status: SegmentChallengeStage[properties.challengeStage],
      challenge_points: properties.points,
      challenge_type: ChallengeType[properties.challenge_type],
      start_utc: undefined,
      completed_utc: undefined,
      challenge_milestone_numerator: properties.progress,
      challenge_milestone_denominator: properties.required
    });
  }

  challengeCompletedModalTapped(properties: IChallengeInfo): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track('Challenge Completed Modal Tapped', {
      track_challenge_id: properties.track_challenge_id,
      user_track_challenge_id: properties.user_track_challenge_id,
      user_track_challenge_key: undefined,
      track_id: undefined,
      challenge_name: undefined,
      challenge_description: undefined,
      challenge_period: ChallengePeriod[properties.challenge_period],
      challenge_status: SegmentChallengeStage[properties.challengeStage],
      challenge_points: properties.points,
      challenge_type: undefined,
      start_utc: undefined,
      completed_utc: undefined,
      awarded_utc: undefined,
      award_points: properties.points,
      challenge_milestone_numerator: undefined,
      challenge_milestone_denominator: undefined
    });
  }

  identify(id?: string, properties?: ISegmentIdentify): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.identify(id, properties);
  }

  identifySMS(phone: string): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.identify(
      {
        phone
      },
      {
        externalIds: [
          {
            id: phone,
            type: 'primary_phone',
            collection: 'users',
            encoding: 'none'
          }
        ]
      }
    );
  }

  page(name: string, theme: segment_theme): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.page(name, {
      theme: segment_theme[theme]
    });
  }

  getAnonymousId(): Observable<string> {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    const obs = new Subject<string>();

    window.analytics.ready(() => {
      obs.next(window.analytics.user().anonymousId());
    });

    return obs;
  }
}
