<!-- Action Modal Starts -->
<div class="action-modal-container">
  <div class="head-container">
    <app-action-modal-header
      [mission]="mission"
      [metaData]="metaData"
      [disableButton]="mission?.isGlobal === 'true' ? false : true"
    ></app-action-modal-header>

    <!-- Action Modal Question Starts -->
    <div
      class=" p-6 pt-0 dark:bg-body-dark bg-body-light border-b-2 action-modal-question border-card-{{
        activeColor
      }} dark:text-white md:pt-0 md:px-24 md:py-8 sm:px-20"
      *ngIf="action"
    >
      <h3
        class="text-xl font-medium"
        [innerHTML]="action.maxdiff?.question | markdown2html"
        data-track="member-maxDiff-question-text"
      ></h3>
      <p
        class="pt-2 text-xs mb-4 text-light-black dark:text-body-light font-semibold opacity-60"
      >
        Select one {{ action?.maxdiff?.low_value_ui_text }} and one
        {{ action?.maxdiff?.high_value_ui_text }}
      </p>

      <p
        class="text-xs mb-2.5"
        *ngIf="mission.action_count > 1 && showSurveyProgress"
      >
        {{ calculateCompletion(action.step_priority, mission.action_count) }}%
        complete
      </p>

      <suzy-preroll-secondary-trigger
        *ngIf="(hasPreroll && prerollViewed) || action.passive_preroll_enabled"
        [prerollKind]="preRollKind"
        [prerollUrl]="preRollUrl"
        (imagePrerollClick)="onViewPrerollImage($event)"
        (linkPrerollClick)="onViewPrerollLink()"
      >
      </suzy-preroll-secondary-trigger>
    </div>
    <!-- Action Modal Question Ends -->
  </div>
  <!-- Action Modal Body Starts -->

  <div
    class="flex-1 md:pb-8 dark:text-white action-body-container"
    [ngClass]="{
      'sm:px-20 modal-body action-modal-body md:px-24 md:py-8':
        hasPreroll && !prerollViewed && !action.passive_preroll_enabled
    }"
  >
    <suzy-preroll
      (prerollViewedEvent)="onPrerollViewedEvent($event)"
      *ngIf="hasPreroll && !prerollViewed && !action.passive_preroll_enabled"
      [preRollKind]="preRollKind"
      [preRollCaption]="preRollCaption"
      [preRollUrl]="preRollUrl"
      [mission]="mission"
      [action]="action"
    ></suzy-preroll>

    <suzy-action-forms-maxDiff
      [action]="action"
      [disableSkip]="disableSkip"
      [fakeCorner]="fakeCorner"
      [isLoading]="isLoading"
      [isSuccess]="isSuccess"
      [disableSkip]="disableSkip"
      [isSubmitting]="isSubmitting"
      [(errorMessage)]="errorMessage"
      (timeToActionUpdate)="onTimeToActionUpdate($event)"
      (updateMetaDataValidationCount)="onUpdateMetaDataValidationCount()"
      (skip)="onSkip($event)"
      (mouseEvent)="onMouseMoveEvent($event)"
      (viewOptionImageEvent)="viewOptionImage($event)"
      (goFormSubmit)="onSubmit()"
      [(errorMessage)]="errorMessage"
      *ngIf="
        !hasPreroll ||
        (hasPreroll && prerollViewed) ||
        action.passive_preroll_enabled
      "
      [disableButton]="mission?.isGlobal === 'true' ? false : true"
      [isSkipping]="isSkipping"
      [isBannedOrDisabled]="isBannedOrDisabled"
    >
    </suzy-action-forms-maxDiff>
  </div>
  <!-- Action Modal Body Ends -->
</div>
<!-- Action Modal Ends -->
<!-- Image Modal Begins -->
<div
  class="image-modal-container bg-box-light dark:bg-box-dark"
  *ngIf="imageModalIsActive"
>
  <button
    type="button"
    class="absolute top-8 right-8 cursor-pointer"
    tabindex="-1"
    (click)="imageModalIsActive = false"
  >
    <span class="text-light-black dark:text-white text-5xl" aria-hidden="true"
      >&times;</span
    >
  </button>
  <div class="image-wrapper">
    <span
      (click)="previousImage(currentChoiceIndex)"
      class="crowdtap-arrow-left text-light-black dark:text-white text-2xl"
    ></span>
    <img
      class="option-image"
      [src]="activeImageUrl"
      [alt]="activeImageAltText"
    />
    <span
      (click)="nextImage(currentChoiceIndex)"
      class="crowdtap-arrow-right text-light-black dark:text-white text-2xl"
    ></span>
  </div>
  <div class="answer-choice-text-wrapper">
    <p class="text-box-dark dark:text-box-light">
      {{ activeAnswerChoiceText }}
    </p>
  </div>
  <div class="slider-navigation-wrapper">
    <ng-container *ngFor="let option of mcOptionsExcludingNone">
      <span
        (click)="viewOptionImage({ option: option })"
        [ngClass]="{ active: option.answer_text === activeAnswerChoiceText }"
      ></span>
    </ng-container>
  </div>
</div>
