<div #wrapper>
  <app-general-header></app-general-header>

  <section aria-label="main" role="main" id="main-content">
    <article class="container">
      <div class="center-column">
        <ng-container
          *ngIf="
            reason === 'Auth.Login.disabled' || reason === 'Auth.Login.banned'
          "
        >
          <img
            class="warning-icon"
            [src]="
              darkMode
                ? 'assets/img/svg/icons/access-denied-banned-dark.svg'
                : 'assets/img/svg/icons/access-denied-banned-light.svg'
            "
          />
          <h1 class="text-light-black dark:text-white">
            {{ 'unauthorized.accountSuspended' | translate }}
          </h1>
          <div class="warning-content">
            <h3 class="font-bold py-4 text-light-black dark:text-white">
              {{ 'unauthorized.whatThisMeans' | translate }}
            </h3>
            <div class="flex flex-row items-center center-center gap-4 pb-4">
              <img
                [src]="
                  'assets/img/svg/icons/ic-alert-triangle' +
                  (darkMode ? '-white' : '') +
                  '.svg'
                "
              />
              <p class="no-space text-light-black dark:text-white">
                {{ 'unauthorized.accountNotFollow' | translate }}
                <a
                  href="https://support.crowdtap.com/hc/en-us/articles/16762298155284"
                  target="_blank"
                  class="text-blue-dark"
                >
                  {{ 'unauthorized.crowdtapGuidelines' | translate }}
                </a>
                .
              </p>
            </div>
            <div class="flex flex-row items-center center-center gap-4 pb-4">
              <img
                [src]="
                  'assets/img/svg/icons/ic-flag' +
                  (darkMode ? '-white' : '') +
                  '.svg'
                "
              />
              <p
                class="no-space text-light-black dark:text-white"
                [innerHTML]="
                  (reason === 'Auth.Login.disabled'
                    ? 'unauthorized.flaggedSecurity'
                    : 'unauthorized.flaggedLowQuality'
                  ) | translate
                "
              ></p>
            </div>
            <div class="flex flex-row items-center center-center gap-4 pb-4">
              <img
                [src]="
                  'assets/img/svg/icons/ic-lock' +
                  (darkMode ? '-white' : '') +
                  '.svg'
                "
              />
              <p
                class="no-space text-light-black dark:text-white"
                [innerHTML]="
                  (reason === 'Auth.Login.disabled'
                    ? 'unauthorized.resolveToRestore'
                    : 'unauthorized.noAccessUntilResolved'
                  ) | translate
                "
              ></p>
            </div>
            <h3 class="font-bold pb-4 text-light-black dark:text-white">
              {{ 'unauthorized.whatCanDo' | translate }}
            </h3>
            <div class="flex flex-row items-center center-center gap-4 pb-4">
              <img
                [src]="
                  'assets/img/svg/icons/ic-chat-bubble' +
                  (darkMode ? '-white' : '') +
                  '.svg'
                "
              />
              <p class="no-space text-blue-dark">
                <a
                  href="http://support.crowdtap.com/"
                  target="_blank"
                  [innerHTML]="
                    (reason === 'Auth.Login.banned'
                      ? 'unauthorized.reportIssue'
                      : 'unauthorized.resolveIssue'
                    ) | translate
                  "
                >
                  {{ 'unauthorized.reportIssue' | translate }}
                </a>
              </p>
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngIf="
            reason !== 'Auth.Login.disabled' && reason !== 'Auth.Login.banned'
          "
        >
          <h1 class="text-light-black dark:text-white">
            {{ 'unauthorized.accountDisabled' | translate }}
          </h1>
          <p class="text-light-black dark:text-white">
            {{ 'unauthorized.youreUnableToLogInBecause' | translate }}
            <a routerLink="/terms-of-service" target="_blank">{{
              'nav.termsOfService' | translate
            }}</a
            >.
          </p>

          <p
            class="text-light-black dark:text-white"
            [innerHTML]="
              'unauthorized.ifYoudLikeToAppealToCrowdtap' | translate
            "
          ></p>

          <a
            href="http://support.crowdtap.com/"
            class="submit-btn"
            target="_blank"
          >
            {{ 'support.contactSupport' | translate }}
          </a>
        </ng-container>
      </div>
    </article>
  </section>

  <app-general-footer> </app-general-footer>
</div>
