import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Injector,
  Renderer2
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  SegmentService,
  TrustArcService,
  VerisoulService,
  segment_theme
} from '@suzy/shared/data-access/tracking';
import { DeviceService } from '@suzy/shared/tools/device';
import { FacebookService, InitParams } from 'ngx-facebook';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { SettingsService } from './core/services/settings.service';
import { SnareServices } from './core/services/snare.service';
import { createCustomElement } from '@angular/elements';
import { PipingTokenComponent } from './shared/components/piping-token/piping-token.component';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.css'],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  deferredPrompt: any;
  mobileAppBanner?: boolean;
  currentPage: string;
  previousPage: string;
  startTime: number;
  loadStartTime: number;
  duration: number;
  loading = false;
  unsubscribe$ = new Subject<void>();
  launchDarklyWarning = false;

  constructor(
    @Inject(DOCUMENT) private document,
    private snareService: SnareServices,
    fb: FacebookService,
    private deviceService: DeviceService,
    private router: Router,
    private settingsService: SettingsService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private segmentService: SegmentService,
    private launchDarklyService: LaunchDarklyService,
    private injector: Injector,
    private trustArcService: TrustArcService,
    private verisoulService: VerisoulService,
    private renderer: Renderer2
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
    this.loadDefaultLanguage();
    const initParams: InitParams = {
      appId: environment.facebookAppId,
      xfbml: true,
      version: 'v3.1'
    };

    fb.init(initParams);

    const pipingTokenElement = createCustomElement(PipingTokenComponent, {
      injector
    });
    customElements.define('app-pipe-token-element', pipingTokenElement);
    this.trustArcService.setSegmentService(this.segmentService);
  }

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.settingsService.initDarkMode();
    });

    this.snareService.init();
    if (this.deviceService.isMobile()) {
      this.document.body.className += ' mobile';

      if (this.deviceService.isiOS()) {
        this.document.body.className += ' iOS';
      }

      if (this.deviceService.isPhone()) {
        this.document.body.className += ' mobile_phone';
      }
    }
    this.segment();

    this.launchDarklyWarning =
      this.launchDarklyService.isUsingLocalFlags() &&
      (environment['allowLaunchDarklyMocks'] ?? false);

    this.initializeVerisoul();
    this.initMobileAppDownloadBanner();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildTitle(obj, prevTitle?): string {
    if (obj.firstChild === null) {
      return obj.data.title;
    }

    const prefix =
      obj.data.title && obj.data.title !== prevTitle
        ? `${obj.data.title} - `
        : '';

    return `${prefix}${this.buildTitle(obj.firstChild, obj.data.title)}`;
  }

  segment(): void {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.segmentService.page(
          event.urlAfterRedirects,
          this.settingsService.isDarkModeSelected.getValue()
            ? segment_theme.dark_mode
            : segment_theme.light_mode
        );
      }
    });
    this.segmentService.load();
  }

  /**
   * Kicks off initialization of the Verisoul integration
   */
  initializeVerisoul(): void {
    if (
      environment.verisoulBundleUri &&
      environment.verisoulBundleUri.length > 0 &&
      environment.verisoulProjectId &&
      environment.verisoulProjectId.length > 0
    ) {
      this.verisoulService.initialize(
        environment.verisoulBundleUri,
        environment.verisoulProjectId
      );
    }
  }

  loadDefaultLanguage(): void {
    const userLanguage = this.translate.getBrowserLang();
    this.translate.use(userLanguage);
  }

  initMobileAppDownloadBanner(): void {
    if (!this.deviceService.isMobile()) {
      return;
    }

    const isAndroid = this.deviceService.getDevice() === 'Android';
    const isIOS = this.deviceService.isiOS()?.length > 0;

    if (isAndroid) {
      window.addEventListener('beforeinstallprompt', event => {
        event.preventDefault();
        this.deferredPrompt = event;
        setTimeout(() => {
          this.mobileAppBanner = true;
          this.deviceService.getAppBannerClose
            .pipe(take(1))
            .subscribe(() => (this.mobileAppBanner = false));
        });
      });

      return;
    }

    if (isIOS) {
      const metaTag = this.renderer.createElement('meta');
      this.renderer.setAttribute(metaTag, 'name', 'apple-itunes-app');
      this.renderer.setAttribute(metaTag, 'content', 'app-id=1440330471');
      this.renderer.appendChild(document.head, metaTag);
      return;
    }
  }
}
