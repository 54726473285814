<!-- Action Modal Starts -->
<div
  class="action-modal-container"
  [attr.data-track]="
    'member-scale-grid-col-' + cols.length + '-row-' + rows.length
  "
>
  <div class="head-container">
    <app-action-modal-header
      [mission]="mission"
      [metaData]="metaData"
      [disableButton]="mission?.isGlobal"
    ></app-action-modal-header>

    <!-- Action Modal Question Starts -->
    <div
      class=" p-6 pt-0 dark:bg-body-dark bg-body-light border-b-2 action-modal-question border-card-{{
        activeColor
      }} dark:text-white md:pt-0 md:px-24 md:py-8 sm:px-20"
    >
      <h3
        class="text-base font-medium md:text-lg md:font-semibold lg:text-xl lg:font-medium mb-4"
        [innerHTML]="action.grid.question | markdown2html : { secure: true }"
        data-track="member-scale-grid-question-text"
      ></h3>
      <div class="text-xs font-semibold mb-4">
        {{ 'grid.rateTheFollowing' | translate }}
      </div>
      <p
        class="text-xs mb-2.5"
        *ngIf="mission.action_count > 1 && showSurveyProgress"
      >
        {{ calculateCompletion(action.step_priority, mission.action_count) }}%
        complete
      </p>

      <suzy-preroll-secondary-trigger
        *ngIf="hasPreroll && prerollViewed"
        [prerollKind]="preRollKind"
        [prerollUrl]="preRollUrl"
        (imagePrerollClick)="onViewPrerollImage($event)"
        (linkPrerollClick)="onViewPrerollLink()"
      >
      </suzy-preroll-secondary-trigger>
    </div>
    <!-- Action Modal Question Ends -->
  </div>
  <!-- Action Modal Body Starts -->
  <div
    class="flex-1 p-6 sm:px-20 md:px-24 md:py-8 dark:text-white action-body-container"
  >
    <suzy-preroll
      (prerollViewedEvent)="onPrerollViewedEvent($event)"
      *ngIf="
        hasPreroll &&
        !prerollViewed &&
        showPreroll &&
        !action.preroll_inline_enabled &&
        !(!action.preroll_inline_enabled && action.hidden_preroll_enabled)
      "
      [preRollKind]="preRollKind"
      [preRollCaption]="preRollCaption"
      [preRollUrl]="preRollUrl"
      [mission]="mission"
      [action]="action"
    ></suzy-preroll>

    <suzy-action-forms-grid-scale
      [isLoading]="isLoading"
      [isSuccess]="isSuccess"
      [disableSkip]="
        disableSkip || mission?.mission_kind === missionKind.external_link
      "
      [isSubmitting]="isSubmitting"
      [isSelected]="isSelected"
      [rows]="rows"
      [cols]="cols"
      [rowErrors]="rowErrors"
      [(errorMessage)]="errorMessage"
      (updateMetaDataValidationCount)="onUpdateMetaDataValidationCount()"
      (skip)="onSkip($event)"
      (gsFormSubmit)="onSubmit($event)"
      *ngIf="
        !hasPreroll ||
        (hasPreroll && prerollViewed) ||
        action.preroll_inline_enabled ||
        (!action.preroll_inline_enabled && action.hidden_preroll_enabled)
      "
      [disableButton]="mission?.isGlobal"
      [isSkipping]="isSkipping"
      [isBannedOrDisabled]="isBannedOrDisabled"
    >
    </suzy-action-forms-grid-scale>
  </div>
  <!-- Action Modal Body Ends -->
</div>
<!-- Action Modal Ends -->
