import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { GlobalIsolateSdkService } from '@suzy/shared/data-access/global-isolate-sdk';
import {
  MissionKind,
  PreRollKind,
  StepKind,
  SuzySdkService,
  WellKnownErrorCodes
} from '@suzy/shared/data-access/suzy-sdk';
import { SegmentService } from '@suzy/shared/data-access/tracking';
import { MissionService } from '@suzy/shared/tools/mission';
import { LightboxComponent } from '@suzy/shared/ui/lightbox';
import swal from 'sweetalert2';
import { MetaData } from '../../../core/models/meta-data.model';
import { AppAlertService } from '../../../core/services/app-alert/app-alert.service';
import { MetaDataService } from '../../../core/services/meta-data.service';
import { ActionComponent } from '../../../views/actions/shared/action-component';
import { MissionExpiredService } from '../../../core/services/mission-expired/mission-expired.service';
import { environment } from 'apps/crowdtap/src/environments/environment';
import { SettingsService } from '../../../core/services/settings.service';
import { GlobalService } from '../../global/global.service';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import { SpecialMissionService } from '../../../core/services/special-mission.service';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { SkipModalComponent } from '../skip-modal/skip-modal.component';
import { SkipModalService } from '../../../core/services/skip-modal-service';
import { beforeModalDismiss } from '../actions.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-open-ended',
  templateUrl: './open-ended.component.html',
  styleUrls: ['./open-ended.component.scss']
})
export class OpenEndedComponent
  extends ActionComponent
  implements OnDestroy, OnInit
{
  @ViewChild('f') responseForm: NgForm;
  options: AnimationOptions = {
    path: '../../../../assets/CT_Present-opening-coin_v2.json'
  };

  optionss: AnimationOptions = {
    path: '../../../../assets/Box-lottie-sparkle-only.json'
  };

  remaining: any;
  response = '';
  isErrorMessage = '';
  isLoading: Boolean = false;
  isSuccess: Boolean = false;
  hasPreroll: Boolean = false;
  prerollViewed: Boolean = false;
  isValid: Boolean = true;
  isSubmitting = false;
  preroll_image: number = PreRollKind.image;
  preroll_link: number = PreRollKind.link;
  mission: any;
  isGlobal = false;
  isFirstAnimationDone = false;
  isBannedOrDisabled = false;

  variantKey = 0;
  question = '';
  preRollUrl = '';
  responseLimit = {
    short: 100,
    medium: 250,
    long: 500
  };
  errorMessage: string;
  randomAmountOfWOrds: number;
  preRollCaption = '';
  preRollKind = PreRollKind.none;
  showPreroll = true;

  metaData: MetaData;
  startDate: number;
  timeToAction: number;
  clientX: number;
  clientY: number;
  timeToActionIsUpdated = false;
  isTrapQuestion: boolean;
  disableSkip: boolean;
  startTime: number;
  activeColor: any = '';
  isSkipping: boolean;
  isSpecialMission: boolean;
  currentUser: any;
  missionKind = MissionKind;
  showSurveyProgress = false;

  private trackActionName = true;

  constructor(
    private suzySDK: SuzySdkService,
    private globalSDK: GlobalIsolateSdkService,
    private route: ActivatedRoute,
    private alerts: AppAlertService,
    private missionService: MissionService,
    private skipModalService: SkipModalService,
    private modals: NgbModal,
    private metaDataService: MetaDataService,
    private segmentService: SegmentService,
    private missionExpiredService: MissionExpiredService,
    private auth: AuthenticationService,
    private hostElement: ElementRef,
    private globalService: GlobalService,
    private settingsService: SettingsService,
    private launchDarklyService: LaunchDarklyService,
    private specialMission: SpecialMissionService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.mission.mission_kind !== this.missionKind.external_cint) {
      this.missionService.chooseMonadicKey(this.mission);
    }
    this.currentUser = this.auth.instantUser();
    this.checkSpecialMission();
    this.missionService.chooseMonadicKey(this.mission);
    this.startTime = new Date().getTime();
    this.trackActionName = this.launchDarklyService.getGLB723Flag();
    this.question = this.action.open_ended.question;
    this.isTrapQuestion = this.action.open_ended.expected_answers_enabled;
    this.showSurveyProgress =
      this.launchDarklyService.getCWDT21752418SurveyProgress();
    this.preRollKind = this.action.preroll_kind;
    this.preRollCaption = this.action.preroll_instruction;
    this.startDate = Date.now();
    this.metaData = {
      userId: '',
      type: 'open_ended',
      actionId: undefined,
      missionId: undefined,
      text: this.question,
      response: this.response,
      attempt: this.startDate,
      startDateTime: this.startDate,
      actionStartDateTime: this.startDate,
      completedDateTime: undefined,
      dwellingTime: undefined,
      completionTime: undefined,
      interactionDateTime: undefined,
      closedDateTime: undefined,
      skipDateTime: undefined,
      skipFlag: false,
      mousePosition: undefined,
      deviceInfo: undefined,
      validationCount: 0,
      questionCounts: undefined,
      responseCount: undefined,
      numberOfOptions: undefined,
      optionPositions: undefined
    };

    if (this.monadicEnabled()) {
      // Monadic question
      this.setupMonadic();
    } else {
      // Not a monadic question
      this.question = this.action.open_ended.question;
      this.preRollKind = this.action.preroll_kind;

      if (
        this.action.preroll_kind !== 'undefined' &&
        this.action.preroll_kind !== PreRollKind.none
      ) {
        this.preRollCaption = this.action.preroll_instruction;
        if (
          this.action.preroll_kind === PreRollKind.image &&
          this.action.preroll
        ) {
          this.hasPreroll = true;
          this.preRollUrl = this.action.preroll.thumbs.full.url;
        } else if (
          this.action.preroll_kind === PreRollKind.link &&
          this.action.preroll_body
        ) {
          this.hasPreroll = true;
          this.preRollUrl = this.action.preroll_body;
        }
      }
    }

    if (
      this.action.open_ended.max_characters === this.responseLimit.medium ||
      this.action.open_ended.max_characters === this.responseLimit.long
    ) {
      const maxWords = 4;
      const minWords = 2;
      this.randomAmountOfWOrds = Math.floor(
        Math.random() * (maxWords - minWords + 1) + minWords
      );
    }

    if (
      (this.mission.first_action && this.mission.first_action.prevent_skip) ||
      this.mission.mission_kind !== this.missionKind.standard
    ) {
      this.disableSkip = this.mission.first_action.prevent_skip;
    }
    this.segmentService.trackActionStarted({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      action_name: this.trackActionName
        ? this.action.open_ended.question
        : undefined,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      preroll_kind: this.action.preroll_kind,
      image_answers: false,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      monadic_variant_id:
        this.action.has_monadic && this.action.monadic_variants.length === 1
          ? this.action.monadic_variants[0].monadic_variant_id
          : undefined,
      points_available:
        this.mission.action_count === 1
          ? this.mission.points
          : this.action.points_extra,
      tag: this.mission.specialType
    });
    this.activeColor = this.mission.color;

    this.skipModalService.isSkip.pipe(take(1)).subscribe(data => {
      if (data === this.metaData.type) {
        this.skipAction();
      }
    });
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.loop = false;
  }

  enterFrame(frame) {
    if (frame.currentTime >= 140) {
      this.isFirstAnimationDone = true;
    }
  }

  checkSpecialMission() {
    this.isSpecialMission =
      this.specialMission.Properties.crowdtap_special_missions.find(
        mission => mission.mission_id === this.mission.mission_id
      );
  }

  setupMonadic(): void {
    this.variantKey =
      this.mission.monadic_variants.length <
      this.mission.monadic_variant_key + 1
        ? 0
        : this.mission.monadic_variant_key;

    if (!this.variantKey) {
      this.variantKey = 0;
    }
    const variant = this.mission.monadic_variants[this.variantKey];

    this.globalService.setMonadicVariantId(variant.monadic_variant_id); //Storing variant ID

    this.hasPreroll = true;
    this.showPreroll = !this.action.passive_preroll_enabled;

    if (!this.showPreroll) {
      this.prerollViewed = true;
    }

    this.preRollCaption = variant.preroll_instruction;
    this.preRollKind = variant.preroll_kind;
    if (this.preRollKind === PreRollKind.image) {
      this.preRollUrl = variant.preroll.thumbs.full.url;
    } else if (this.preRollKind === PreRollKind.link) {
      this.preRollUrl = variant.preroll_body;
    }
  }

  monadicEnabled(): boolean {
    return this.action.has_monadic;
  }

  init(): void {
    this.remaining = this.action.open_ended.max_characters;
  }

  onViewPrerollImage(guid?: string): void {
    this.segmentService.trackMediaOpen({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      asset_id: this.action.preroll ? this.action.preroll.asset_id : undefined,
      asset_url: this.preRollUrl,
      preroll_kind: this.preRollKind,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      is_on_question: true,
      image_attribuion: guid
    });
  }

  onViewPrerollLink(): void {
    this.segmentService.trackMediaOpen({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      asset_id: this.action.preroll ? this.action.preroll.asset_id : undefined,
      asset_url: this.preRollUrl,
      preroll_kind: this.preRollKind,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      is_on_question: true
    });
  }

  ngOnDestroy(): void {
    this.subject.complete();
    this.subject = undefined;
  }

  onPrerollViewedEvent(guid?: string): void {
    this.segmentService.trackMediaOpen({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      asset_id: this.action.preroll ? this.action.preroll.asset_id : undefined,
      asset_url: this.preRollUrl,
      preroll_kind: this.preRollKind,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      is_on_question: false,
      image_attribuion: guid
    });
    this.prerollViewed = true;
  }

  validateInput(input): Boolean {
    this.isSubmitting = false;
    this.response = input;
    const wordsAmount = input.split(' ').filter(v => {
      return v !== '';
    });
    const isMinCharactersValid =
      input.trim().length >= this.action.open_ended.min_characters;

    if (!isMinCharactersValid) {
      this.errorMessage = `Please enter at least ${this.action.open_ended.min_characters} characters`;
      this.metaData.validationCount++;
      this.segmentTrackValidationError(this.errorMessage);

      return (this.isValid = false);
    } else {
      if (this.action.open_ended.max_characters === this.responseLimit.short) {
        return (this.isValid = true);
      } else {
        if (wordsAmount.length >= this.randomAmountOfWOrds) {
          return (this.isValid = true);
        } else {
          this.errorMessage = 'Please make your response more descriptive';
          this.metaData.validationCount++;
          this.segmentTrackValidationError(this.errorMessage);

          return (this.isValid = false);
        }
      }
    }
  }

  segmentTrackValidationError(errorMessage: string): void {
    this.segmentService.trackValidationError({
      action_id: this.action.action_id,
      action_kind: this.action.action_kind,
      brand_id: this.mission.brand_id,
      error_code: undefined,
      error_source: 'frontend',
      error_message: errorMessage,
      error_ui_message: errorMessage,
      mission_id: this.mission.mission_id,
      mission_kind: this.mission.mission_kind,
      monadic_variant_id:
        this.action.has_monadic && this.action.monadic_variants.length === 1
          ? this.action.monadic_variants[0].monadic_variant_id
          : undefined,
      tag: this.mission.specialType
    });
  }

  skipAction() {
    this.sendMetaData('skip');
    this.isSubmitting = true;
    this.isSkipping = true;

    this.hostElement.nativeElement
      .closest('.modal')
      .classList.add('is-skipping');
    const input = {
      brand_id: this.mission.brand_id,
      mission_id: this.mission.mission_id,
      action_id: this.action.action_id
    };
    this.suzySDK.ProtocolRespond.skip(
      this.mission.brand_id,
      this.mission.mission_id,
      input
    ).subscribe(
      data => {
        if (data.success) {
          this.skipModalService.isSuccess.next(true);
          setTimeout(() => {
            this.subject.next('skip');
            this.isSkipping = false;

            if (this.mission.action_count === 1) {
              this.segmentService.trackMission('Mission Skipped', {
                action_count: this.mission.action_count,
                brand_id: this.mission.brand_id,
                mission_id: this.mission.mission_id,
                points_available: this.mission.points,
                mission_kind: this.mission.mission_kind,
                has_cover: false,
                monadic_variant_quantity: this.mission.monadic_variants
                  ? this.mission.monadic_variants.length
                  : undefined,
                tag: this.mission.specialType,
                weight: this.mission.weight
              });
            }
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data.meta
            );
            this.subject.next(data);
          }, 1000);
        } else {
          this.skipModalService.isSuccess.next(false);
          this.isSkipping = false;
          let message = '';
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
          }
          this.isErrorMessage = message;
        }
      },
      error => {
        this.skipModalService.isSuccess.next(false);
        this.isSkipping = false;
        this.isErrorMessage = error;
      },
      () => {
        this.isSubmitting = false;
      }
    );
  }

  skip($event): void {
    $event.preventDefault();
    this.openSkipModal();
  }

  openSkipModal(): void {
    const modalClass = `modal-${new Date().getTime()}`;
    let modal;
    modal = this.modals.open(SkipModalComponent, {
      windowClass: modalClass,
      beforeDismiss: () => {
        return beforeModalDismiss(modalClass, 'skip');
      }
    });
    const instance = modal.componentInstance as SkipModalComponent;
    instance.kind = this.mission.mission_kind;
    instance.mission = this.mission;
    instance.action = this.mission.first_action;
    instance.actionService = null;
    instance.actionType = this.metaData.type;
  }

  onMouseMoveEvent(event: MouseEvent): void {
    this.clientX = event.clientX;
    this.clientY = event.clientY;
  }

  updateTimeToAction(): void {
    if (!this.timeToActionIsUpdated) {
      this.timeToAction = Date.now();
    }
    this.timeToActionIsUpdated = true;
    this.isErrorMessage = '';
    this.isBannedOrDisabled = false;
  }

  sendMetaData(actionType: string): void {
    let completedDateTime: number;
    let skipDateTime: number;
    let skipFlag: boolean;
    switch (actionType) {
      case 'submit':
        completedDateTime = Date.now();
        break;
      case 'skip':
        skipDateTime = Date.now();
        skipFlag = true;
        break;
      default:
        break;
    }

    const meta = {
      ...this.metaData,
      actionId: this.action.action_id,
      missionId: this.mission.mission_id,
      text: this.question,
      response: this.response,
      completedDateTime,
      interactionDateTime: this.timeToAction,
      skipDateTime,
      skipFlag,
      mousePosition: { x: this.clientX, y: this.clientY }
    };

    this.metaDataService.createMetaData(meta);
  }

  stripNewLines(input: string): string {
    return input.replace(/[\r\n]+/g, ' ');
  }

  onSubmit(): void {
    if (this.isSubmitting) {
      return;
    }
    let input: any;

    this.sendMetaData('submit');
    this.segmentService.trackAction('Action Completed', {
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      action_name: this.trackActionName
        ? this.action.open_ended.question
        : undefined,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      monadic_variant_id:
        this.action.has_monadic && this.action.monadic_variants.length === 1
          ? this.action.monadic_variants[0].monadic_variant_id
          : undefined,
      tag: this.mission.specialType
    });

    input = {
      action_id: this.action.action_id,
      answer_id: this.action.open_ended.answer_id,
      monadic_variant_id: this.variantKey,
      response_value: this.stripNewLines(this.response),
      response_display: this.stripNewLines(this.response)
    };

    if (this.settingsService.isGlobalApp()) {
      const variantId = this.globalService.getMonadicVariantId() ?? null;
      if (variantId) {
        input.monadic_variant_id = variantId;
      }
    }
    this.isSubmitting = true;
    if (this.monadicEnabled()) {
      input.monadic_variant_id =
        this.mission.monadic_variants[this.variantKey].monadic_variant_id;
    }
    this.isLoading = true;
    if (
      this.mission.isGlobal ||
      this.settingsService.isPreviewApp() ||
      this.settingsService.isGlobalApp() ||
      this.settingsService.isExternalApp()
    ) {
      this.globalResponse(input);
    } else {
      this.crowdtapResponse(input);
    }
  }

  crowdtapResponse(input: any): void {
    input = {
      ...input,
      correlation_data: this.action.correlation_data
        ? this.action.correlation_data
        : undefined
    };

    const respond$ = this.mission.is_tutorial
      ? this.suzySDK.TutorialResponse.respondToAction(input)
      : this.suzySDK.ProtocolRespond.respond(
          this.mission.brand_id,
          this.mission.mission_id,
          input
        );

    respond$.subscribe(
      data => {
        if (data.success) {
          this.isLoading = false;
          this.isSuccess = true;
          if (this.isSpecialMission) {
            const wrapper = this.hostElement.nativeElement.querySelector(
              '.action-modal-container'
            );
            wrapper.classList.add('is-hidden');
          }
          setTimeout(() => {
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data.meta
            );
            this.subject.next(data);
          }, 800);

          if (
            data.error_code &&
            data.error_code === WellKnownErrorCodes.onboarding_trap_warning
          ) {
            this.isSuccess = false;
            this.segmentService.trackQuestionFailedModalViewed({
              action_id: this.action.action_id,
              brand_id: this.mission.brand_id,
              mission_id: this.mission.mission_id
            });
            swal.fire({
              customClass: 'general-modal attention-modal',
              html:
                'Your response was flagged as going against one of our ' +
                '<a href="https://support.crowdtap.com/hc/en-us/articles/360030907972-Community-guidelines" target="_blank">Community Guidelines.</a>' +
                ' Make sure to read thoroughly, answer honestly, be respectful and follow our guidelines to remain in good standing.' +
                '</br>' +
                '</br>' +
                'Your account will be disabled after the 3rd warning.',
              title: 'Attention!',
              imageUrl: '../../../../assets/img/svg/icons/ic-warning-blue.svg',
              imageWidth: 80,
              imageHeight: 80,
              confirmButtonText: 'I Understand'
            });
          }
          if (
            data.error_code &&
            data.error_code === WellKnownErrorCodes.onboarding_trap_banned
          ) {
            this.isSuccess = false;
            this.segmentService.trackQuestionFailedModalViewed({
              action_id: this.action.action_id,
              brand_id: this.mission.brand_id,
              mission_id: this.mission.mission_id
            });
            swal
              .fire({
                customClass: 'general-modal',
                title: `Account Suspended`,
                html:
                  'You didn’t meet our attention check requirements. If this was done in error, please ' +
                  '<a href="https://support.crowdtap.com/hc/en-us?_ga=2.32338235.443825342.1658849519-400642095.1658179860" target="_blank">contact support</a>',
                imageUrl: '../../../../assets/img/svg/icons/ic-suspension.svg',
                imageWidth: 94,
                imageHeight: 94,
                imageAlt: 'Temporary suspension',
                confirmButtonText: 'Got it'
              })
              .then(result => {
                if (result) {
                  this.auth.logout(false, false, '/');
                }
              });
          }
        } else {
          let message = '';
          this.isLoading = false;
          this.isSubmitting = false;
          this.isBannedOrDisabled = ['user.Banned', 'user.Disabled'].some(
            x => x === data.message_token
          );

          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += data.errors[error];
            }
          }

          let errorCode = [404, 409, 401];
          if (errorCode.includes(data.error_code)) {
            this.openMissionExpiredModal(message);
          } else {
            this.isErrorMessage = message;
          }
        }
      },
      error => {
        this.isLoading = false;
        this.isSubmitting = false;
        this.isBannedOrDisabled = false;
        let errorCode = [404, 409, 401];
        if (errorCode.includes(error.error_code)) {
          this.openMissionExpiredModal(error.message);
        } else {
          this.isErrorMessage = error.message;
        }
      }
    );
  }

  globalResponse(input): void {
    input = {
      payload: {
        ...input,
        correlation_data: this.action.correlation_data
          ? this.action.correlation_data
          : undefined
      }
    };
    this.globalService.respondToGlobalActionAsync(input).subscribe(
      data => {
        if (data.success) {
          this.isLoading = false;
          this.isSuccess = true;
          if (this.isSpecialMission) {
            const wrapper = this.hostElement.nativeElement.querySelector(
              '.action-modal-container'
            );
            wrapper.classList.add('is-hidden');
          }
          setTimeout(() => {
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data.meta
            );
            this.subject.next(data);
          }, 800);
        } else {
          let message = '';
          this.isLoading = false;
          this.isSubmitting = false;
          this.isBannedOrDisabled = ['user.Banned', 'user.Disabled'].some(
            x => x === data.message_token
          );
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += data.errors[error];
            }
            this.isErrorMessage = message;
          }
        }
      },
      error => {
        this.isErrorMessage = error;
        this.isBannedOrDisabled = false;
        this.isLoading = false;
        this.isSubmitting = false;
      }
    );
  }
  openMissionExpiredModal(message: any) {
    this.missionExpiredService.notify({
      mission: this.mission,
      title: 'Error',
      message: message
    });
  }

  calculateCompletion(stepPriority: number, actionCount: number): number {
    return Math.round(((stepPriority - 1) / actionCount) * 100);
  }
}
