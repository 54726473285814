import {
  ActionKind,
  ActionStructureGridKind,
  ActionStructureMultipleChoiceKind,
  ChallengePeriod,
  ChallengeType,
  MissionKind,
  PreRollKind,
  RewardCategory
} from '@suzy/shared/data-access/suzy-sdk';

export interface ISegmentIdentify {
  account_disabled?: Date;
  address1?: string;
  address2?: string;
  anonymized_utc?: Date;
  birthdate?: Date;
  brands?: string;
  city?: string;
  country?: string;
  created_at?: Date;
  created_utc?: Date;
  current_number_of_points?: number;
  education_name?: string;
  email?: string;
  employment_name?: string;
  ethnicity_name?: string;
  first_name?: string;
  gender_name?: string;
  handle?: string;
  has_profile_photo?: boolean;
  household_income?: string;
  industry_name?: string;
  industry_title_name?: string;
  ip_score?: number;
  is_referral?: boolean;
  last_login_utc?: Date;
  last_name?: string;
  occupation?: string;
  onboarding_status_name?: string;
  parenting_name?: string;
  phone?: string;
  postal_code?: string;
  profile_completed?: Date;
  raf_eligible?: boolean;
  redemption_count?: number;
  region_name?: string;
  relationship_status?: string;
  script_id?: string;
  sign_up_method?: string;
  size_of_household?: string;
  state?: string;
  status?: number;
  total_points?: number;
  total_risk_score?: number;
  user_status_name?: string;
  verification_status_name?: string;
}

export interface ISegmentMissionBase {
  action_count: number;
  brand_id: string;
  mission_id: string;
  mission_kind: MissionKind;
  tag?: segment_special_tile;
  weight?: number;
}

export interface ISegmentMissionComplete extends ISegmentMissionBase {
  points_earned?: number;
}

export interface ISegmentMission extends ISegmentMissionBase {
  points_available?: number;
  has_cover?: boolean;
  monadic_variant_quantity?: number;
}

export interface ISegmentAction {
  action_kind: ActionKind;
  action_id: string;
  action_name?: string;
  brand_id: string;
  grid_kind?: ActionStructureGridKind;
  mission_id: string;
  monadic_variant_id?: string;
  multiple_choice_kind?: ActionStructureMultipleChoiceKind;
  tag?: segment_special_tile;
}

export interface ISegmentActionStarted extends ISegmentAction {
  has_monadic?: boolean;
  image_answers: boolean;
  passive_preroll_enabled: boolean;
  points_available?: number;
  preroll_kind?: PreRollKind;
}

export interface ISegmentMedia {
  action_id: string;
  action_kind: ActionKind;
  asset_id: string;
  asset_url?: string;
  brand_id: string;
  mission_id: string;
  preroll_kind: PreRollKind;
  passive_preroll_enabled: boolean;
  is_on_question: boolean;
  image_attribuion?: string;
}

export interface ISegmentError {
  error_message?: string;
  error_source: string;
  error_ui_message?: string;
}

export interface ISegmentErrorPromo extends ISegmentError {
  promo_code: string;
}

export interface ISegmentErrorAuth extends ISegmentError {
  method: string;
}

export interface ISegmentErrorAction extends ISegmentError {
  action_id: string;
  action_kind?: ActionKind;
  brand_id: string;
  error_code?: string;
  error_context?: string;
  mission_id: string;
  mission_kind?: MissionKind;
  monadic_variant_id?: string;
  tag?: segment_special_tile;
}

export interface ISegmentReward {
  points_cost: number;
  retailer: string;
  reward_id: string;
  reward_category: RewardCategory;
  reward_value: number;
}

export interface ISegmentDashboard {
  actions_available: number;
  api_missions_total_available: number;
  special_birthday?: number;
  special_profile?: number;
  special_sparkle?: number;
  special_dollar_circle?: number;
  special_celebrate?: number;
  special_question?: number;
  special_star_target?: number;
  special_streak?: number;
  special_clock_nine?: number;
  special_user_exclamation?: number;
  special_chat?: number;
  special_double_points?: number;
  weighted_missions?: number;
  special_pumpkin_sweater?: number;
  special_leaf_sweater?: number;
  special_fall_sweater?: number;
  special_cauldron?: number;
  special_champagne?: number;
  special_new_year?: number;
  special_shopping?: number;
  special_christmas_tree?: number;
  special_sweater_christmas_tree?: number;
  special_sweater_snowflake?: number;
  special_sweater_snowman?: number;
  special_pumpkin?: number;
}

export interface ISegmentDashboardCleared {
  points_earned?: number;
  ui_message?: string;
}

export interface ISegmentDashboardTapped {
  button_text?: string;
  empty_dashboard_message?: string;
}

export enum segment_theme {
  dark_mode = 0,
  light_mode = 1
}

export interface ISegmentThemeUpdated {
  theme: segment_theme;
}

export interface ISegmentTrapFailedModal {
  action_id: string;
  brand_id: string;
  mission_id: string;
}

export interface IPreviewSegmentLinkCopied {
  brand_id: string;
  mission_id: string;
  mission_kind: MissionKind;
  preview_url: string;
}

export interface IPreviewSegmentMission {
  action_count: number;
  brand_id: string;
  has_cover: boolean;
  mission_id: string;
  mission_kind: MissionKind;
  preview_device: string;
  click_path?: string;
  monadic_variant_quantity?: number;
}

export interface ISetRewardGoalTapped {
  reward_goal_location: SegmentRewardGoalLocation;
  total_points: number;
}

export interface IRewardGoalTrackId extends IRewardGoal {
  track_reward_id?: string;
}

export interface IRewardGoalReached extends IRewardGoal {
  points_cost: number;
}

export interface IRewardOrder extends IRewardGoal {
  points_cost: number;
  reward_goal: Boolean;
  retailer: string;
}

export interface IRewardGoalTapped extends IRewardGoal {
  reward_goal_location: SegmentRewardGoalLocation;
  track_reward_id: string;
}

export interface IReward {
  reward_category?: RewardCategory;
  reward_id?: string;
  reward_name?: string;
  reward_photo?: string;
  total_points?: number;
}

export interface ISMSVerificationRedemption {
  points_cost?: number;
  reward_category?: RewardCategory;
  reward_name?: string;
  reward_photo?: string;
  reward_value?: string;
  user_track_reward_id?: string;
  track_reward_id?: string;
  track_phone_number?: number;
}

export interface ISMSVerificationError extends ISMSVerificationRedemption {
  sms_verification_error_type?: SegmentSMSVerificationErrorType;
  error_message?: string;
  error_ui_message?: string;
  error_source?: SegmentErrorSource;
}

export interface IRewardGoal extends IReward {
  reward_goal_points_cost?: number;
  reward_value?: number;
  user_track_reward_goal_id?: string;
}

export interface IRewardGoalMilestone {
  reward_category: RewardCategory;
  reward_name: string;
  reward_photo?: string;
  reward_value: number;
  total_points: number;
  track_reward_id?: string;
  user_track_reward_goal_id: string;
}

export interface IAvailableChallenge {
  track_challenge_id: string;
  track_id: string;
  challenge_name: string;
  challenge_description: string;
  challenge_period: ChallengePeriod;
  challenge_points: number;
  challenge_type: ChallengeType;
  start_utc: string;
  expires_utc: string;
}

export interface IChallengeButton {
  points: number;
  track_challenge_id: string;
  challenge_period?: ChallengePeriod;
  challenge_type?: ChallengeType;
}

export interface IChallengeInfo extends IChallengeButton {
  required: number;
  progress: number;
  challengeStage: SegmentChallengeStage;
  user_track_challenge_id?: string;
}

export interface ISegmentOnboardingTooltipViewed {
  ui_message?: string;
  dismissised?: boolean;
  type?: string;
}

export enum SegmentSMSVerificationErrorType {
  invalid_code = 1,
  no_code_provided = 2,
  expired_code = 3
}

export enum SegmentErrorSource {
  frontend = 1,
  backend = 2
}

export enum SegmentRewardGoalLocation {
  points_pill = 1,
  rewards = 2
}

export enum SegmentPermissionName {
  push = 1,
  sms = 2,
  storage = 3
}

export enum segment_special_tile {
  birthday_tile = 1,
  profile_tile = 2,
  sparkle_tile = 3,
  dollar_circle_tile = 4,
  celebrate_tile = 5,
  question_tile = 6,
  star_target_tile = 7,
  streak_tile = 8,
  clock_nine_tile = 9,
  user_exclamation_tile = 10,
  chat_tile = 11,
  double_points_tile = 12,
  pumpkin_sweater_tile = 13,
  leaf_sweater_tile = 14,
  fall_sweater_tile = 15,
  cauldron_tile = 16,
  champagne_tile = 17,
  new_year_tile = 18,
  shopping_tile = 19,
  christmas_tree_tile = 20,
  sweater_christmas_tree_tile = 21,
  sweater_snowflake_tile = 22,
  sweater_snowman_tile = 23,
  pumpkin_tile = 24,
  auto_launch = 25
}

export enum SegmentChallengeStage {
  available = 0,
  started = 1,
  completed = 2,
  awarded = 3
}
