<form novalidate="" (ngSubmit)="onSubmit()" #f="ngForm" class="h-full">
  <!-- Form Content Starts -->
  <div class="form">
    <div class="form-group form-filling">
      <div class="form-field-container">
        <div
          class="action-row"
          [ngClass]="{ darkMode: isDarkMode }"
          *ngFor="let row of rows; let i = index"
        >
          <h4 class="text-black dark:text-white">
            {{ row[0].row.answer_text }}
          </h4>
          <div class="slider">
            <div
              class="slider-header"
              data-track="member-scale-grid-slider-header"
            >
              <div
                [ngClass]="{ darkMode: isDarkMode }"
                *ngFor="let col of cols; let j = index"
                [class.selected]="row[j].isSelected"
                [class.hideColor]="
                  (cols.length / 2 | number : '1.0-0') === j + 1
                "
                [attr.data-track]="
                  'member-scale-grid-slider-slot-' + i + '-' + j
                "
              >
                <span class="text-black dark:text-white">{{ j + 1 }}</span>
              </div>
            </div>
            <input
              (touchstart)="onSliderGrab($event, row)"
              (touchend)="onSliderRelease($event, row)"
              (mousedown)="onSliderGrab($event, row)"
              (input)="onSliderUpdate($event, row)"
              (mouseup)="onSliderRelease($event, row)"
              (change)="onSliderChange($event, row)"
              type="range"
              min="0"
              max="{{ (cols.length - 1) * 100 }}"
              value="{{ (cols.length - 1) * 50 }}"
              [attr.data-track]="'member-scale-grid-slider-' + i"
            />
          </div>
          <div
            class="terminal-labels"
            [attr.data-track]="'terminal-labels-' + i"
          >
            <div class="text-xs font-semibold text-black dark:text-white">
              {{ cols[0][0].col.ui_text }}
            </div>
            <div class="text-xs font-semibold text-black dark:text-white">
              {{ cols[cols.length - 1][0].col.ui_text }}
            </div>
          </div>
          <div
            class="my-6 text-red-500 row-error sm:px-10"
            *ngIf="rowErrors.length && rowErrors[i] && submitted"
          >
            <svg
              class="inline-block mr-2"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.50016 4.04156C8.06294 4.04156 7.7085 4.396 7.7085 4.83323V7.99989C7.7085 8.43712 8.06294 8.79156 8.50016 8.79156C8.93739 8.79156 9.29183 8.43712 9.29183 7.99989V4.83323C9.29183 4.396 8.93739 4.04156 8.50016 4.04156ZM9.22849 10.8658C9.21116 10.8153 9.1872 10.7674 9.15724 10.7233L9.06224 10.6045C8.83459 10.3799 8.49414 10.3143 8.19933 10.4383C8.10339 10.4784 8.01503 10.5346 7.93807 10.6045C7.78989 10.7539 7.70727 10.9562 7.70849 11.1666C7.70974 11.2701 7.73126 11.3723 7.77182 11.4674C7.84381 11.6663 8.00043 11.823 8.19933 11.8949C8.39094 11.9796 8.60938 11.9796 8.80099 11.8949C8.99988 11.823 9.15651 11.6663 9.22849 11.4674C9.26906 11.3723 9.29058 11.2701 9.29183 11.1666C9.29571 11.1139 9.29571 11.061 9.29183 11.0083C9.2782 10.9578 9.25683 10.9097 9.22849 10.8658ZM8.50016 0.083252C4.12791 0.083252 0.583496 3.62766 0.583496 7.99992C0.583496 12.3722 4.12791 15.9166 8.50016 15.9166C12.8724 15.9166 16.4168 12.3722 16.4168 7.99992C16.4168 5.90029 15.5828 3.88665 14.0981 2.40199C12.6134 0.917327 10.5998 0.083252 8.50016 0.083252ZM8.50017 14.3332C5.00237 14.3332 2.16684 11.4977 2.16684 7.99989C2.16684 4.50209 5.00237 1.66656 8.50017 1.66656C11.998 1.66656 14.8335 4.50209 14.8335 7.99989C14.8335 9.6796 14.1662 11.2905 12.9785 12.4782C11.7908 13.666 10.1799 14.3332 8.50017 14.3332Z"
                fill="#FF5F57"
              />
            </svg>

            <span class="font-medium align-middle text-error scale-error">{{
              'grid.dragTheScrubberToGiveARating' | translate
            }}</span>
          </div>
        </div>
        <div
          class="action-row"
          [ngClass]="{ darkMode: isDarkMode }"
          *ngIf="isBannedOrDisabled"
        >
          <div class="my-6 text-red-500 row-error sm:px-10">
            <svg
              class="inline-block mr-2"
              width="24"
              height="24"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.50004 4.04351C8.06282 4.04351 7.70837 4.39795 7.70837 4.83518V8.00185C7.70837 8.43907 8.06282 8.79351 8.50004 8.79351C8.93727 8.79351 9.29171 8.43907 9.29171 8.00185V4.83518C9.29171 4.39795 8.93727 4.04351 8.50004 4.04351ZM9.22837 10.8677C9.21104 10.8173 9.18708 10.7694 9.15712 10.7252L9.06212 10.6065C8.83447 10.3818 8.49402 10.3163 8.1992 10.4402C8.10326 10.4803 8.0149 10.5366 7.93795 10.6065C7.78976 10.7559 7.70715 10.9581 7.70837 11.1686C7.70962 11.272 7.73114 11.3742 7.7717 11.4694C7.84368 11.6683 8.00031 11.8249 8.1992 11.8969C8.39082 11.9816 8.60925 11.9816 8.80087 11.8969C8.99976 11.8249 9.15639 11.6683 9.22837 11.4694C9.26894 11.3742 9.29045 11.272 9.2917 11.1686C9.29559 11.1159 9.29559 11.0629 9.2917 11.0102C9.27807 10.9597 9.25671 10.9117 9.22837 10.8677ZM8.50004 0.0852051C4.12779 0.0852051 0.583374 3.62962 0.583374 8.00187C0.583374 12.3741 4.12779 15.9185 8.50004 15.9185C12.8723 15.9185 16.4167 12.3741 16.4167 8.00187C16.4167 5.90224 15.5826 3.88861 14.098 2.40394C12.6133 0.91928 10.5997 0.0852051 8.50004 0.0852051ZM8.50005 14.3352C5.00225 14.3352 2.16672 11.4996 2.16672 8.00185C2.16672 4.50404 5.00225 1.66851 8.50005 1.66851C11.9979 1.66851 14.8334 4.50404 14.8334 8.00185C14.8334 9.68155 14.1661 11.2925 12.9784 12.4802C11.7907 13.6679 10.1798 14.3352 8.50005 14.3352Z"
                fill="#FF5F57"
              />
            </svg>
            <span
              class="font-medium align-middle text-error scale-error"
              style="white-space: normal"
            >
              {{ 'unauthorized.yourAccountFlagged' | translate }}
              <a
                href="https://support.crowdtap.com/hc/en-us/articles/16762298155284"
                target="_blank"
                >{{ 'unauthorized.clickHereMission' | translate }}</a
              >.
            </span>
          </div>
        </div>
      </div>
      <!-- Form fields : Container for Radio/Checkbox Ends -->
    </div>

    <div
      class="relative flex items-center justify-center py-12 space-x-2 align-middle form-group"
    >
      <div *ngIf="!disableSkip">
        <button
          class="text-sm font-bold w-36 md:text-base text-blue-dark skip"
          (click)="onSkip($event)"
          data-track="member-scale-grid-skip"
          [disabled]="isSkipping"
          [ngClass]="{ loading: isSkipping }"
        >
          <span class="btn-label"> {{ 'general.skip' | translate }}</span>
          <span class="dot dot-1"></span>
          <span class="dot dot-2"></span>
          <span class="dot dot-3"></span>
        </button>
      </div>
      <div>
        <button
          class="animation-submit-btn"
          [ngClass]="{
            primarySection: !isLoading && !isSuccess,
            loadingSection: isLoading,
            successSection: isSuccess
          }"
          data-track="member-scale-grid-continue"
          [disabled]="isSkipping || isSubmitting || isLoading"
        >
          <div class="primaryTitle text submit-label">
            {{ 'general.submit' | translate }}
          </div>
          <div class="primaryTitle text continue-label">
            {{ 'general.continue' | translate }}
          </div>
          <div class="loadingTitle text">
            <suzy-spinner
              class="absolute left-0 right-0 button-spinner top-3"
              size="small"
              button="true"
              [round]="7"
              [color]="'#ffffff'"
              [overlay]="false"
            >
            </suzy-spinner>
          </div>
          <div class="successTitle text">
            <svg class="animated-check" viewBox="0 0 24 24">
              <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
            </svg>
          </div>
        </button>
      </div>
    </div>
  </div>
  <!-- Form Content Ends -->
</form>
