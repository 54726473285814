<div #wrapper>
  <app-general-header [viewKind]="'signup'"></app-general-header>

  <section aria-label="main" role="main" id="main-content">
    <article
      class="flex flex-col mt-6 w-full px-4 m-auto max-w-auth sm:max-w-sm-auth"
    >
      <div
        *ngIf="!isNewSignupQuestions && !isSmsFirstFlowSingUp"
        class="overflow-hidden progress-bar text-xs flex rounded bg-gray"
      >
        <div
          [ngStyle]="{ width: 0 + '%' }"
          class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-dark"
        ></div>
      </div>
      <div class="center-column">
        <h1
          class="mt-6 mb-8 text-2xl font-bold text-center text-light-black dark:text-white sm:text-4xl"
        >
          {{ 'auth.createYourAccount' | translate }}
        </h1>

        <ng-container *ngIf="!isNewSignupQuestions && !isSmsFirstFlowSingUp">
          <div>
            <ul class="flex items-center justify-center mt-4 space-x-5">
              <li
                class="flex items-center justify-center rounded-md bg-active-link w-14 h-14 dark:bg-active-link-dark"
              >
                <button
                  class="flex items-center justify-center w-full h-full"
                  [disabled]="isLoading"
                  (click)="socialSignIn('facebook')"
                >
                  <svg
                    width="15"
                    height="27"
                    viewBox="0 0 15 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9655 0.766663H10.9482C9.17235 0.766663 7.46929 1.4427 6.21361 2.64606C4.95793 3.84942 4.2525 5.48152 4.2525 7.18333V11.0333H0.235107V16.1667H4.2525V26.4333H9.60902V16.1667H13.6264L14.9655 11.0333H9.60902V7.18333C9.60902 6.84297 9.75011 6.51655 10.0012 6.27588C10.2524 6.0352 10.593 5.9 10.9482 5.9H14.9655V0.766663Z"
                      fill="#4092FF"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </div>
          <p
            class="pt-6 pb-4 text-center text-active-link dark:active-link-drak line"
          >
            <span
              class="font-semibold text-light-black dark:text-white px-2 bg-transparent"
            >
              {{ 'auth.or' | translate }}
            </span>
          </p>
        </ng-container>

        <form [formGroup]="signUpForm">
          <div class="mb-6 form-row">
            <label class="font-medium text-light-black dark:text-white">
              {{ 'auth.emailAddress' | translate }}
            </label>

            <div class="emailField" #emailField>
              <input
                type="email"
                class="block w-full p-2 mt-2 font-medium border rounded-sm h-11 text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                formControlName="email"
                placeholder="{{ 'auth.emailAddress' | translate }}"
                data-track="member-sign-up-enter-email"
              />
            </div>
            <small
              *ngIf="fieldErrors('email')?.email"
              class="form-text text-muted danger"
            >
              {{ 'auth.pleaseEnterAValidEmailAddress' | translate }}
            </small>
            <small
              *ngIf="fieldErrors('email')"
              class="form-text text-muted danger"
            >
              <span *ngIf="fieldErrors('email').required">
                {{ 'auth.emailRequired' | translate }}
              </span>
            </small>
            <small
              *ngIf="fieldErrors('email')?.server"
              class="form-text text-muted danger"
            >
              {{ fieldErrors('email').server }}
            </small>
          </div>

          <div class="mb-6 form-row">
            <label class="font-medium text-light-black dark:text-white">{{
              'auth.password' | translate
            }}</label>
            <div class="relative passwordField" #passwordFields>
              <span
                class="ic-eye"
                *ngIf="isChange"
                (click)="toggleVisibility($event)"
              ></span>
              <input
                type="password"
                [attr.type]="!eyeView ? 'password' : 'text'"
                class="block w-full p-2 mt-2 font-medium border rounded-sm h-11 text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                formControlName="password"
                placeholder="{{ 'auth.password' | translate }}"
                (keyup)="onChangeHandler($event)"
                autocomplete="none"
                data-track="member-sign-up-create-password"
              />
              <span
                class="absolute z-10 w-4 h-4 cursor-pointer right-3 top-3"
                (click)="eyeView = !eyeView"
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  class="text-black dark:text-white"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.5">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.1796 10.15C17.4121 6.04625 14.0871 3.5 10.4996 3.5C6.91213 3.5 3.58713 6.04625 1.81963 10.15C1.72223 10.3732 1.72223 10.6268 1.81963 10.85C3.58713 14.9537 6.91213 17.5 10.4996 17.5C14.0871 17.5 17.4121 14.9537 19.1796 10.85C19.277 10.6268 19.277 10.3732 19.1796 10.15ZM10.5001 15.75C7.7264 15.75 5.1014 13.7463 3.58765 10.5C5.1014 7.25375 7.7264 5.25 10.5001 5.25C13.2739 5.25 15.8989 7.25375 17.4126 10.5C15.8989 13.7463 13.2739 15.75 10.5001 15.75ZM10.5005 7C8.56749 7 7.00049 8.567 7.00049 10.5C7.00049 12.433 8.56749 14 10.5005 14C12.4335 14 14.0005 12.433 14.0005 10.5C14.0005 9.57174 13.6317 8.6815 12.9754 8.02513C12.319 7.36875 11.4287 7 10.5005 7ZM10.5005 12.25C9.53399 12.25 8.75049 11.4665 8.75049 10.5C8.75049 9.5335 9.53399 8.75 10.5005 8.75C11.467 8.75 12.2505 9.5335 12.2505 10.5C12.2505 11.4665 11.467 12.25 10.5005 12.25Z"
                      fill="currentColor"
                    />
                    <path
                      *ngIf="!eyeView"
                      d="M21 1L11 10.75L1 20.5"
                      stroke="currentColor"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </span>
            </div>
            <small
              *ngIf="
                fieldErrors('password')?.uppercase ||
                fieldErrors('password')?.lowercase ||
                fieldErrors('password')?.nonletter ||
                fieldErrors('password')?.minlength ||
                fieldErrors('password')?.maxlength
              "
              class="form-text text-muted danger"
            >
              {{ 'auth.pleaseEnterAValidPassword' | translate }}
            </small>
            <small
              *ngIf="fieldErrors('password')"
              class="form-text text-muted danger"
            >
              <span
                *ngIf="
                  fieldErrors('password')?.required &&
                  (isFieldTouched('password') || this.submitted)
                "
              >
                {{ 'auth.passwordRequired' | translate }}
              </span>
            </small>
          </div>

          <ng-container *ngIf="isNewSignupQuestions || isSmsFirstFlowSingUp">
            <div class="mb-6 form-row">
              <label class="text-light-black dark:text-white">
                {{ 'profile.firstName' | translate }}
              </label>

              <div #firstName>
                <input
                  type="text"
                  class="block w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                  formControlName="first_name"
                  autocomplete="given-name"
                  placeholder="{{ 'profile.firstName' | translate }}"
                  data-track="member-sign-up-first-name"
                />
              </div>
              <small
                *ngIf="fieldErrors('first_name')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('first_name').required">
                  {{ 'auth.nameIsRequired' | translate }}
                </span>
              </small>
            </div>
            <div class="mb-6 form-row">
              <label class="text-light-black dark:text-white">
                {{ 'profile.lastName' | translate }}
              </label>

              <div #lastName>
                <input
                  type="text"
                  class="block w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                  formControlName="last_name"
                  placeholder="{{ 'profile.lastName' | translate }}"
                  data-track="member-sign-up-last-name"
                />
              </div>
              <small
                *ngIf="fieldErrors('last_name')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('last_name').required">
                  {{ 'auth.lastNameIsRequired' | translate }}
                </span>
              </small>
            </div>
          </ng-container>
          <div class="mb-6" *ngIf="isSmsFirstFlowSingUp">
            <div class="form-row">
              <div class="relative check-container age-consent-given">
                <input
                  type="checkbox"
                  id="age-consent-given"
                  data-track="member-sign-up-age-consent-given"
                  formControlName="age_consent_given"
                />
                <label
                  for="age-consent-given"
                  [class.dark-checkbox-tick]="darkMode$ | async"
                  class="custom-checkbox text-light-black dark:text-white flex"
                >
                  <span class="text-xs font-semibold">{{
                    'profile.ageConsentGiven' | translate
                  }}</span>
                </label>
              </div>
            </div>
            <small
              *ngIf="fieldErrors('age_consent_given')?.required"
              class="form-text text-muted danger"
            >
              <span> You must confirm to continue </span>
            </small>

            <div class="mt-6 from-row">
              <div class="relative check-container c-terms">
                <input
                  type="checkbox"
                  id="c-terms"
                  formControlName="c_terms"
                  data-track="member-sign-up-c-terms"
                />
                <label
                  class="custom-checkbox text-light-black dark:text-white flex"
                  for="c-terms"
                  [class.dark-checkbox-tick]="darkMode$ | async"
                >
                  <span class="text-xs font-semibold">
                    {{ 'almostThere.agreement' | translate }}
                    <a
                      routerLink="/terms-of-service"
                      class="text-blue-dark underline"
                    >
                      Terms of Service
                    </a>
                    and
                    <a
                      routerLink="/privacy-policy"
                      class="text-blue-dark underline"
                      >Privacy Policy</a
                    >
                  </span>
                </label>
              </div>
            </div>
            <small
              *ngIf="fieldErrors('c_terms')?.required"
              class="form-text text-muted danger"
            >
              <span>
                You must accept Crowdtap's Terms of Service and Privacy Policy
              </span>
            </small>
          </div>
          <div class="text-center">
            <button
              type="submit"
              (click)="continueWithEmail()"
              data-track="member-sign-up-continue"
              class="relative mb-4 font-bold text-white rounded-full w-68 h-14 bg-blue-dark"
              [disabled]="isLoading"
            >
              {{ 'auth.continueWithEmail' | translate }}
              <div class="absolute left-8 button-spinner top-7">
                <suzy-spinner
                  *ngIf="isLoading"
                  size="small"
                  button="true"
                  [round]="10"
                  [color]="'#ffffff'"
                  [overlay]="false"
                >
                </suzy-spinner>
              </div>
            </button>
          </div>
        </form>
      </div>
    </article>
  </section>
</div>
<app-general-footer id="main-footer"> </app-general-footer>
