import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, Input } from '@angular/core';
import { DeviceService } from '@suzy/shared/tools/device';

@Component({
  selector: 'app-download-app-banner',
  templateUrl: './download-app-banner.component.html',
  styleUrls: ['./download-app-banner.component.scss'],
  animations: [
    trigger('slideUp', [
      state('void', style({ transform: 'translateY(100%)', opacity: 0 })),
      state('*', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('void => *', [
        animate('0.5s 1s ease-out') // 1s animation with a 2s delay
      ])
    ])
  ]
})
export class DownloadAppBannerComponent {
  constructor(private deviceService: DeviceService) {}

  @Input() prompt?: any;

  onCloseClick(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.deviceService.closeGetAppBanner();
  }

  async onOpenClick() {
    if (!this.prompt) {
      return;
    }
    this.prompt.prompt();
    await this.prompt.userChoice;
    this.prompt = null;
    this.deviceService.closeGetAppBanner();
  }
}
