import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  OnInit,
  ElementRef
} from '@angular/core';
import { NgForm } from '@angular/forms';

declare var $;

export interface AnswerChoice {
  col: any;
  row: any;
  isSelected: boolean;
  hasError: boolean;
}

export interface TurfAction {
  helpText: string;
  noneText: string;
  minChoices: number;
  maxChoices: number;
  options: Array<any>;
  question: string;
  variants: Array<any>;
  has_monadic: boolean;
}

@Component({
  selector: 'suzy-action-forms-turf',
  templateUrl: './turf.component.html',
  styleUrls: ['./turf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TurfComponent implements OnInit {
  radioInput: any;
  checkboxInput: any;
  isDarkMode: boolean;
  sliderTouched = false;
  roundValue: number;

  @Input() isLoading: boolean;
  @Input() isSuccess: boolean;
  @Input() action: any;
  @Input() turf: TurfAction;
  @Input() isTrapQuestion: boolean;
  @Input() disableSkip: boolean;
  @Input() errorMessage: string;
  @Input() disableButton: Boolean = false;
  @Input() isSkipping: boolean;
  @Input() selectedRadio: any;
  @Input() isSubmitting: boolean = false;
  @Input() isBannedOrDisabled: boolean;

  @Output() checkboxChange = new EventEmitter<any>();
  @Output() skip = new EventEmitter<Event>();
  @Output() mouseEvent = new EventEmitter<MouseEvent>();
  @Output() turfFormSubmit = new EventEmitter<NgForm>();
  @Output() turfFormSubmitPrerequisite = new EventEmitter<NgForm>();
  @Output() viewOptionImageEvent = new EventEmitter<any>();

  @ViewChild('f') responseForm: NgForm;

  get selectedValue(): string {
    if (!this.action?.turf?.options?.length) {
      return '';
    }

    const index = this.action.turf.options.findIndex((x: any) => x.isSelected);
    if (index === -1) {
      return '';
    }

    return (index + 1).toString();
  }
  constructor() {}

  ngOnInit(): void {
    this.roundValue = 10;
    this.isDarkMode = $('html').hasClass('dark');
  }

  chooseAtLeastXChoices(): boolean {
    return (
      this.turf.minChoices > 1 &&
      this.turf.maxChoices >= this.turf.options.length &&
      this.turf.minChoices < this.turf.options.length
    );
  }

  chooseBetweenXandYChoices(): boolean {
    return (
      !this.chooseAtLeastXChoices() &&
      this.turf.minChoices > 1 &&
      this.turf.maxChoices < this.turf.options.length &&
      this.turf.minChoices !== this.turf.maxChoices
    );
  }

  onCheckBoxChange(option, event, index): void {
    this.checkboxChange.emit({
      option,
      event,
      index
    });
    if (this.checkboxInput?.length) {
      this.turf.options.map(item => {
        if (item.is_other && !item.isSelected) {
          this.checkboxInput = '';
        }
      });
    }
  }

  onSkip(event: Event): void {
    this.skip.emit(event);
  }

  onMouseMoveEvent(event: MouseEvent): void {
    this.mouseEvent.emit(event);
  }

  calculateOptionsValue(value: string): number {
    const valueNum = parseInt(value, 10);
    const newValue = Math.round(valueNum / 100);

    return newValue;
  }

  updateOptionsValue(
    element: HTMLInputElement,
    options: Array<AnswerChoice>
  ): void {
    const value = this.calculateOptionsValue(element.value);
    options.map((item, index) => {
      item.isSelected = `${index}` === `${value}` ? true : false;
    });
  }

  onSubmit(): void {
    this.turfFormSubmit.emit(this.responseForm);
  }

  onSubmitPrerequisite(): void {
    this.turfFormSubmitPrerequisite.emit(this.responseForm);
  }
}
