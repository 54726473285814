import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  basicLogger,
  initialize,
  LDClient,
  LDContext,
  LDFlagChangeset,
  LDFlagSet,
  LDOptions
} from 'launchdarkly-js-client-sdk';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LaunchDarklyService {
  token: string;
  allowMocks = false;
  private client: LDClient;
  private customCache: { [key: string]: string | number | boolean } = {};
  private localFlags: {};

  constructor(private http: HttpClient) {}

  initialize(token: string, allowMocks: boolean): Promise<void> {
    this.token = token;
    this.allowMocks = allowMocks;
    this.load();

    if (this.allowMocks) {
      this.loadLocalFlags();
    }

    return this.client.waitUntilReady();
  }

  load(): void {
    const userContext: LDContext = {
      kind: 'user',
      anonymous: true
    };

    const options: LDOptions = {
      logger: basicLogger({ level: 'warn' })
    };
    this.client = initialize(this.token, userContext, options);

    this.client.on('change', (updatedFlags: LDFlagChangeset) => {
      this.updateFlags(updatedFlags);
    });

    this.client.on('failed', () => {
      console.log('LaunchDarkly failed to load.');
    });
  }

  identify(
    userId: string,
    custom?: { [key: string]: string | number | boolean }
  ): Promise<LDFlagSet> {
    var userContext: LDContext;
    if (userId) {
      if (custom) {
        Object.entries(custom).forEach(([key, value]) => {
          this.customCache[key] = value;
        });
      }

      userContext = {
        kind: 'user',
        key: userId,
        anonymous: false,
        custom: this.customCache
      };
    } else {
      userContext = {
        kind: 'user',
        anonymous: true,
        custom: this.customCache
      };
    }
    return this.client.identify(userContext);
  }

  loadLocalFlags(): void {
    this.http.get('/mocks/launchdarkly.json').subscribe((flags: any) => {
      this.localFlags = flags;
    });
  }

  isUsingLocalFlags(): boolean {
    return (
      this.allowMocks &&
      this.localFlags &&
      Object.keys(this.localFlags).length > 0
    );
  }

  track(eventName: string, data?: any): void {
    this.client.track(eventName, data);
  }

  flagRAFChange: Subject<boolean> = new Subject<boolean>();
  flagCTG88Change: Subject<string> = new Subject<string>();

  // only need subject and updateFlags() if you need to know if a flag changes after page load
  updateFlags(flags: LDFlagChangeset): void {
    for (const key in flags) {
      if (flags.hasOwnProperty(key)) {
        const flag = flags[key];
        switch (key) {
          case 'ctg-88-nudge-content-on-board-clear':
            this.flagCTG88Change.next(flag.current);
            break;
          default:
        }
      }
    }
  }

  private tryGetFlag(
    key: string
  ): string | boolean | number | string[] | undefined {
    // Debug values for Haseeb
    // console.log(this.allowMocks, this.localFlags, this.localFlags[key], key);
    try {
      if (this.allowMocks && this.localFlags && this.localFlags[key]) {
        return this.localFlags[key];
      }

      let response = this.client.variation(key, undefined);
      if (response === undefined) {
        console.log('LauchDarkly flag not found ' + key);
      }

      return response;
    } catch (error) {
      console.log(error);

      return undefined;
    }
  }

  private tryGetFlagString(key: string): string {
    const flag: string | boolean | number | string[] | undefined =
      this.tryGetFlag(key);
    if (typeof flag === 'string' || typeof flag === 'undefined') {
      return this.tryGetFlag(key) as string;
    }
    console.log(
      "LaunchDarkly type mismatch '" +
        typeof flag +
        "' expected 'string' " +
        key
    );

    return undefined;
  }

  private tryGetFlagBoolean(key: string): boolean {
    const flag: string | boolean | number | string[] | undefined =
      this.tryGetFlag(key);
    if (typeof flag === 'boolean' || typeof flag === 'undefined') {
      return this.tryGetFlag(key) as boolean;
    }
    console.log(
      "LaunchDarkly type mismatch '" +
        typeof flag +
        "' expected 'boolean' " +
        key
    );

    return undefined;
  }

  private tryGetFlagNumber(key: string): number {
    const flag: string | boolean | number | string[] | undefined =
      this.tryGetFlag(key);
    if (typeof flag === 'number' || typeof flag === 'undefined') {
      return this.tryGetFlag(key) as number;
    }
    console.log(
      "LaunchDarkly type mismatch '" +
        typeof flag +
        "' expected 'number' " +
        key
    );

    return undefined;
  }

  private tryGetFlagJSON(key: string): string[] {
    const flag: string | boolean | number | string[] | undefined =
      this.tryGetFlag(key);
    if (
      (typeof flag === 'object' && Array.isArray(flag)) ||
      typeof flag === 'undefined'
    ) {
      return this.tryGetFlag(key) as Array<string>;
    }
    console.log(
      "LaunchDarkly type mismatch '" + typeof flag + "' expected 'JSON' " + key
    );

    return undefined;
  }

  getRAFFlag(): boolean {
    return this.tryGetFlagBoolean('refer-a-friend');
  }

  getCTG88Flag(): string {
    return this.tryGetFlagString('ctg-88-nudge-content-on-board-clear');
  }

  getCWDT1126Flag(): boolean {
    return this.tryGetFlagBoolean(
      'cwdt-1126-add-profile-update-available-to-identify'
    );
  }

  getCWDT1119Flag(): boolean {
    return this.tryGetFlagBoolean('cwdt-1119-segment-notification-page');
  }

  getCWDT1119VariantFlag(): boolean {
    return this.tryGetFlagBoolean(
      'cwdt-1119-variant-segment-notifications-page'
    );
  }

  getGLB723Flag(): boolean {
    return this.tryGetFlagBoolean(
      'glb-723-glba-fe-iso-segment-first-identify-page-and-track-events'
    );
  }

  getCWDT1187Flag(): boolean {
    return this.tryGetFlagBoolean(
      'cwdt-1187-show-special-tile-ui-on-certain-missions'
    );
  }

  getCWDT1187VariantFlag(): boolean {
    return this.tryGetFlagBoolean(
      'cwdt-1187-variant-show-special-tile-ui-on-certain-missions'
    );
  }

  getCWDT803Flag(): boolean {
    return this.tryGetFlagBoolean('cwdt-803-screen-attribution-overlay');
  }

  getCWDT1308Flag(): Array<string> {
    return this.tryGetFlagJSON('cwdt-1308-special-tile-snack-bar-copy');
  }

  getCWDT1376Flag(): boolean {
    return this.tryGetFlagBoolean('cwdt-1376-spring-theme');
  }

  getCWDT1376SM1612Flag(): boolean {
    return this.tryGetFlagBoolean('cwdt-1376-sm-1612-spring-theme');
  }

  getCWDT1397Flag(): boolean {
    return this.tryGetFlagBoolean('cwdt-1397-remove-points-from-tiles');
  }
  getCWDT742Flag(): boolean {
    return this.tryGetFlagBoolean('cwdt-742-reward-goal-v-1');
  }

  getCWDT742SM1562Flag(): boolean {
    return this.tryGetFlagBoolean('cwdt-742-sm-1562-reward-goal-v-1');
  }

  getCWDT1448Flag(): boolean {
    return this.tryGetFlagBoolean('cwdt-1448-points-pill-tooltip-flag');
  }

  getCWDT1193Flag(): boolean {
    return this.tryGetFlagBoolean(
      'cwdt-1193-milestone-progress-toward-reward-goal'
    );
  }

  getCWDT1193SM1563Flag(): Array<string> {
    return this.tryGetFlagJSON(
      'cwdt-1193-sm-1563-milestone-progress-towards-reward-goal'
    );
  }

  getCWDT1182Flag(): boolean {
    return this.tryGetFlagBoolean('cwdt-1182-challenge-missions-completed-v-1');
  }

  getCWDT1182SM1691Flag(): boolean {
    return this.tryGetFlagBoolean(
      'cwdt-1182-sm-1691-challenge-missions-completed-v-1'
    );
  }

  getCWDT1562SM1709Flag(): boolean {
    return this.tryGetFlagBoolean('cwdt-1562-sm-1709-summer-theme');
  }

  getCWDT1562Flag(): boolean {
    return this.tryGetFlagBoolean('cwdt-1562-summer-theme');
  }

  getCWDT1796Flag(): boolean {
    return this.tryGetFlagBoolean('cwdt-1796-ability-to-maintain-a-streak');
  }

  getCWDT1796SM1809Flag(): boolean {
    return this.tryGetFlagBoolean(
      'cwdt-1796-sm-1809-ability-to-maintain-a-streak'
    );
  }

  getCWDT1372Flag(): boolean {
    return this.tryGetFlagBoolean(
      'cwdt-1372-ability-to-set-a-reward-goal-during-sign-up'
    );
  }

  getSDOM358AddVisaGiftCard(): boolean {
    return this.tryGetFlagBoolean(
      'sdom-358-add-feature-flag-to-visa-gift-card'
    );
  }

  getSDOM747MakeMissionSpecialTileFlag(): string[] {
    return this.tryGetFlagJSON('sdom-747-make-mission-special-tile');
  }

  getCWDT24102411HolidayStreakMilestoneModalFlag(): any[] {
    return this.tryGetFlagJSON('cwdt-2410-2411-holiday-streak-milestone-modal');
  }

  getPlg161UpdateProfilePointsBanner(): number {
    return this.tryGetFlagNumber('plg-161-update-profile-points-banner');
  }

  getCWDT1847SM1879Flag(): boolean {
    return this.tryGetFlagBoolean('cwdt-1847-sm-1879-7-day-streak-challenge');
  }

  getCWDT2172SignUpQuestionsFlag(): boolean {
    return this.tryGetFlagBoolean(
      'cwdt-2172-sm-2115-add-more-profile-fields-to-sign-up'
    );
  }

  getCWDT2504And2505StreakMilestone(): boolean {
    return this.tryGetFlagBoolean('cwdt-2504-2505-streak-milestone');
  }

  getCWDT2328MoveSMSQuestionFlag(): boolean {
    return this.tryGetFlagBoolean(
      'cwdt-2328-2329-move-sms-prior-to-profile-screens'
    );
  }

  getCWDT21752418SurveyProgress() {
    return this.tryGetFlagBoolean('cwdt-2175-2418-survey-progress');
  }

  getCWDT2541And2542AddVenmoAndPayPalRewards(): boolean {
    return this.tryGetFlagBoolean('cwdt-2541-2542-add-venmo-and-paypal-reward');
  }

  getCWDT2108And2417ShowTimeOnTiles(): boolean {
    return this.tryGetFlagBoolean('cwdt-2108-2417-show-time-on-tiles');
  }

  getCWDT2433And2434AddTileTooltip(): boolean {
    return this.tryGetFlagBoolean('cwdt-2433-2434-add-tile-tooltip');
  }
}
