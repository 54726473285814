<div class="download-banner" [@slideUp]>
  <a class="close-btn close" aria-label="Close" (click)="onCloseClick($event)">
    <span aria-hidden="true">&times;</span>
  </a>
  <span> {{ 'homepage.downloadBanner' | translate }} </span>

  <button
    data-track="download-app-banner-open"
    [innerHTML]="'homepage.appDownload' | translate"
    (click)="onOpenClick()"
  ></button>
</div>
