<div class="action-modal-container">
  <suzy-spinner *ngIf="isSubmitting"></suzy-spinner>

  <div class="head-container">
    <app-action-modal-header
      [mission]="mission"
      [disableButton]="mission?.isGlobal"
    >
    </app-action-modal-header>

    <div class="action-modal-question">
      <h3>{{ action.share_photo.prompt }}</h3>
    </div>
    <p
      class="text-xs mb-2.5"
      *ngIf="mission.action_count > 1 && showSurveyProgress"
    >
      {{ calculateCompletion(action.step_priority, mission.action_count) }}%
      complete
    </p>
  </div>

  <div class="modal-body action-modal-body" data-simplebar>
    <!-- NOTE: data attri is need for SimpleBar Module -->

    <suzy-preroll
      (prerollViewedEvent)="onPrerollViewedEvent()"
      *ngIf="hasPreroll && !prerollViewed"
      [preRollKind]="preRollKind"
      [preRollCaption]="preRollCaption"
      [preRollUrl]="preRollUrl"
      [mission]="mission"
      [action]="action"
    ></suzy-preroll>

    <form
      novalidate=""
      #f="ngForm"
      *ngIf="!hasPreroll || (hasPreroll && prerollViewed)"
    >
      <div class="form">
        <div class="form-group form-filling">
          <div
            class="upload-placeholder"
            [ngClass]="{ 'no-border': uploadedPhotoUrl }"
          >
            <p [ngClass]="{ 'display-hidden': uploadedPhotoUrl }">
              {{ 'photo.uploadYourImage' | translate }}
            </p>
            <suzy-file-upload
              [getUploadUrl]="getUploadUrl"
              (uploadComplete)="onUploadComplete($event)"
              [fileTypes]="'.png, .jpg, .jpeg, .gif'"
            >
            </suzy-file-upload>

            <img
              *ngIf="uploadedPhotoUrl"
              src="{{ uploadedPhotoUrl }}"
              class="img-fluid img-file"
              alt="Uploaded photo"
            />
            <!-- NOTE: Fallback for IE / Edge -->
            <div
              *ngIf="uploadedPhotoUrl"
              class="ms-browser-img"
              [ngStyle]="{ backgroundImage: 'url(' + uploadedPhotoUrl + ')' }"
            ></div>

            <button
              *ngIf="uploadedPhotoUrl"
              (click)="uploadedPhotoUrl = ''"
              class="util util-close"
            ></button>
            <!--
              <button *ngIf="uploadedPhotoUrl" class="util util-crop"></button>
              <button *ngIf="uploadedPhotoUrl" class="util util-preview"></button>
              -->
          </div>

          <div class="action-modal-tooltip">
            <span>
              <a
                ng-href="#"
                class="border-bottom"
                (click)="showGuidelines($event)"
                >{{ 'general.clickHere' | translate }}</a
              >
              {{ 'photo.readResponseGuidelines' | translate }}
            </span>
            <!-- <ng-template #tipContent>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis fermentum ligula pulvinar sapien fermentum, in
              ultricies est aliquet. Sed rhoncus risus ac lacinia ornare.</ng-template>
            <span class="tooltip-icon tooltip-icon-info float-right" [ngbTooltip]="tipContent">
              <i>i</i>
            </span> -->
          </div>

          <!-- <p> TODO: Do we need the Modal Dialog for Photo Guide Line?
            <a href="javascript:;" (click)="">Click here</a>
            to read the response guidelines
          </p> -->

          <div class="form-group">
            <textarea
              type="text"
              class="form-control h-72"
              name="caption"
              rows="3"
              maxlength="200"
              placeholder="caption"
              required
              [(ngModel)]="photoCaption"
            ></textarea>
            <p class="char-counter">{{ 200 - photoCaption.length }}/200</p>
          </div>

          <app-share-social-media
            [message]="photoCaption"
            [hashtags]="action.share_photo.hashtags"
            [url]="uploadedPhotoUrl"
            (shared)="onShared()"
            [platforms]="action.share_photo.platforms"
          >
          </app-share-social-media>
        </div>

        <div class="form-group form-cta">
          <div class="row">
            <div class="col-6">
              <button
                type="submit"
                class="btn form-btn form-btn-secondary text-uppercase"
                (click)="skip($event)"
                [disabled]="disableSkip"
                *ngIf="mission?.isGlobal"
              >
                {{ 'general.skip' | translate }}
              </button>
            </div>
            <div class="col-6">
              <button
                (click)="onSubmit()"
                class="btn form-btn form-btn-primary form-btn-with-arrow text-uppercase"
                [disabled]="!shared"
              >
                <div class="button-spinner" *ngIf="isSubmitting">
                  <suzy-spinner
                    size="small"
                    button="true"
                    [overlay]="false"
                  ></suzy-spinner>
                </div>
                {{ 'general.submit' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
