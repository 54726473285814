import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  Renderer2
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { DeviceService } from '@suzy/shared/tools/device';
declare var $;
export interface AnswerChoice {
  col: any;
  row: any;
  isSelected: boolean;
  hasError: boolean;
}

@Component({
  selector: 'suzy-action-forms-grid-scale',
  templateUrl: './grid-scale.component.html',
  styleUrls: ['./grid-scale.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridScaleComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() isSuccess: boolean;
  @Input() disableSkip: boolean;
  @Input() isSubmitting: boolean;
  @Input() isSelected: boolean;
  @Input() rowErrors: any[];
  @Input() cols: any[];
  @Input() rows: any[];
  @Input() errorMessage: string;
  @Input() disableButton: Boolean = false;
  @Input() isSkipping: boolean;
  @Input() isBannedOrDisabled: boolean;

  @Output() updateMetaDataValidationCount = new EventEmitter();
  @Output() errorMessageChange = new EventEmitter<string>();
  @Output() skip = new EventEmitter<Event>();
  @Output() gsFormSubmit = new EventEmitter<NgForm>();
  submitted = false;

  @ViewChild('f') responseForm: NgForm;

  isDarkMode: boolean;
  roundValue: number;
  constructor(private deviceService: DeviceService) {}

  ngOnInit(): void {
    let screenName = this.deviceService.getDevice();
    this.roundValue = 10;
    this.isDarkMode = $('html').hasClass('dark');
  }

  onSliderGrab(event: Event, row: Array<AnswerChoice>): void {
    const element = event.srcElement as HTMLInputElement;
    const slider = element.parentElement;

    slider.classList.add('grabbed');
    $('div').removeClass('hideColor');
    const value = this.calculateRowValue(element.value);
    this.updateRowValue(element, row);
    element.value = `${value * 100}`;
    element.blur();
  }

  onSliderUpdate(event: Event, row: Array<AnswerChoice>): void {
    const element = event.srcElement as HTMLInputElement;
    this.updateRowValue(element, row);
  }

  onSliderChange(event: Event, row: Array<AnswerChoice>): void {
    const element = event.srcElement as HTMLInputElement;
    const slider = element.closest('.slider');

    const value = this.calculateRowValue(element.value);
    this.updateRowValue(element, row);
    element.value = `${value * 100}`;
    element.blur();
  }

  onSliderRelease(event: Event, row: Array<AnswerChoice>): void {
    const element = event.srcElement as HTMLInputElement;
    const slider = element.closest('.slider');

    slider.classList.add('touched');
    slider.classList.remove('grabbed');
    this.validateScaleQuestion();
  }

  updateRowValue(element: HTMLInputElement, row: Array<AnswerChoice>): void {
    const value = this.calculateRowValue(element.value);
    row.map((item, index) => {
      item.isSelected = `${index}` === `${value}` ? true : false;
    });
  }

  calculateRowValue(value: string): number {
    const valueNum = parseInt(value, 10);
    const newValue = Math.round(valueNum / 100);

    return newValue;
  }

  gridHasError(): any {
    const hasError = this.validateScaleQuestion();
    this.errorMessage = hasError
      ? 'You must indicate an answer choice for all questions before submitting.'
      : '';
    this.errorMessageChange.emit(this.errorMessage);
    return hasError;
  }

  // validation for scale type
  validateScaleQuestion(): any {
    let hasError = false;

    this.rows.forEach((choice, idx) => {
      const selectedChoice = [];
      choice.forEach(item => {
        if (item.isSelected === true) {
          selectedChoice.push(item);
        }
      });

      if (selectedChoice.length !== 1) {
        this.rowErrors[idx] = true;
        this.updateMetaDataValidationCount.emit();
        hasError = true;
      } else {
        this.rowErrors[idx] = false;
      }
    });

    return hasError;
  }

  onSkip(event: Event): void {
    this.skip.emit(event);
  }

  scrollToTopError(): void {
    const scaleError = document.getElementsByClassName('scale-error');

    if (scaleError.length <= 0) {
      return;
    }

    const parent = scaleError[0].closest('.action-row');
    parent.scrollIntoView({ behavior: 'smooth' });
  }

  onSubmit(): void {
    this.submitted = true;
    if (!this.gridHasError()) {
      this.gsFormSubmit.emit(this.responseForm);

      return;
    }

    setTimeout(() => {
      this.scrollToTopError();
    });
  }
}
